import React from 'react'
import {
    BundleDiscount,
    getNextBundleSize,
    SALE_ACTIVE,
    SALE_AMOUNT,
    useAppState,
} from '../AppState'

const useNextBundleSize = () => {
    const { totalPhotos } = useAppState()

    if (SALE_ACTIVE) {
        if (totalPhotos < 8 && BundleDiscount?.['8'] > SALE_AMOUNT!)
            return {
                photosUntilNextBundle: 8 - totalPhotos,
                nextBundle: 8,
                nextBundleSale: BundleDiscount?.['8'],
            }

        if (totalPhotos < 12 && BundleDiscount?.['12'] > SALE_AMOUNT!)
            return {
                photosUntilNextBundle: 12 - totalPhotos,
                nextBundle: 12,
                nextBundleSale: BundleDiscount?.['12'],
            }

        if (totalPhotos < 16 && BundleDiscount?.['16'] > SALE_AMOUNT!)
            return {
                photosUntilNextBundle: 16 - totalPhotos,
                nextBundle: 16,
                nextBundleSale: BundleDiscount?.['16'],
            }

        return null
    } else {
        if (totalPhotos < 8)
            return {
                photosUntilNextBundle: 8 - totalPhotos,
                nextBundle: 8,
                nextBundleSale: BundleDiscount?.['8'],
            }

        if (totalPhotos < 12)
            return {
                photosUntilNextBundle: 12 - totalPhotos,
                nextBundle: 12,
                nextBundleSale: BundleDiscount?.['12'],
            }

        if (totalPhotos < 16)
            return {
                photosUntilNextBundle: 16 - totalPhotos,
                nextBundle: 16,
                nextBundleSale: BundleDiscount?.['16'],
            }

        return null
    }
}

export default useNextBundleSize
