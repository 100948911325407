import React, { ComponentProps, useState } from 'react'
import { Box, Input as DSInput, styled, ErrorBox } from 'design-system'
import { useFormContext } from 'react-hook-form'
import AlertIcon from '../../assets/icons/alert_circle.svg'
interface InputProps {
    name: string
    label?: string
}

const Label = styled('label')

const Input = ({
    name,
    label,
    css,
    children,
    onBlur,
    onFocus,
    autoFocus,
    ...rest
}: InputProps & ComponentProps<typeof DSInput>) => {
    const { register, getFieldState, formState } = useFormContext()
    const { error } = getFieldState(name, formState)
    const [isFocused, setIsFocused] = useState(autoFocus)

    return (
        <Box
            css={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                mb: 8,
                ...css,
            }}
        >
            {label && (
                <Label
                    htmlFor={name}
                    css={{
                        fontWeight: 700,
                        color: isFocused ? '$brand' : '#606367',
                        marginBottom: '4px',
                        position: 'absolute',
                        top: -7,
                        left: 10,
                        background: '#fff',
                        fontSize: 12,
                        px: 4,
                    }}
                >
                    {label}
                </Label>
            )}
            <Box css={{ display: 'flex', alignItems: 'flex-end', gap: '$2' }}>
                <DSInput
                    id={name}
                    css={{ flexGrow: 1 }}
                    {...register(name)}
                    aria-invalid={error ? 'true' : 'false'}
                    {...rest}
                    isError={Boolean(error)}
                    autoFocus={autoFocus}
                    onBlur={(event) => {
                        setIsFocused(false)
                        onBlur?.(event)
                    }}
                    onFocus={(event) => {
                        setIsFocused(true)
                        onFocus?.(event)
                    }}
                />
                {!!children && children}
            </Box>

            {error && (
                <Box css={{ marginTop: '6px' }}>
                    <ErrorBox icon={AlertIcon}>{error.message}</ErrorBox>
                </Box>
            )}
        </Box>
    )
}

export default Input
