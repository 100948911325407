import * as React from 'react'
import { styled, CSS } from './stitches.config'
import * as SliderPrimitive from '@radix-ui/react-slider'

const SliderTrack = styled(SliderPrimitive.Track, {
    'position': 'relative',
    'flexGrow': 1,
    'backgroundColor': '#E2E2E2',
    'borderRadius': '$pill',
    '&[data-orientation="horizontal"]': {
        height: 2,
    },
    '&[data-orientation="vertical"]': {
        width: 2,
        height: 100,
    },
})

const SliderRange = styled(SliderPrimitive.Range, {
    'position': 'absolute',
    'background': '$brand',
    'borderRadius': 'inherit',
    '&[data-orientation="horizontal"]': {
        height: '100%',
    },
    '&[data-orientation="vertical"]': {
        width: '100%',
    },
})
const SliderThumb = styled(SliderPrimitive.Thumb, {
    all: 'unset',
    display: 'block',
    width: 26,
    height: 26,
    backgroundColor: 'white',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.35)',
    borderRadius: 1000,
})

export const StyledSlider = styled(SliderPrimitive.Root, {
    'position': 'relative',
    'display': 'flex',
    'alignItems': 'center',
    'flexShrink': 0,
    'userSelect': 'none',
    'touchAction': 'none',
    'height': 15,
    'flexGrow': 1,

    '&[data-orientation="vertical"]': {
        flexDirection: 'column',
        width: 15,
    },

    '@hover': {
        '&:hover': {
            [`& ${SliderTrack}`]: {},
            [`& ${SliderThumb}`]: {},
        },
    },
})

type SliderPrimitiveProps = React.ComponentProps<typeof SliderPrimitive.Root>
type SliderProps = SliderPrimitiveProps & { css?: CSS }

export const Slider = React.forwardRef<
    React.ElementRef<typeof StyledSlider>,
    SliderProps
>((props, forwardedRef) => {
    const hasRange = Array.isArray(props.defaultValue || (props as any).value)
    const thumbsArray = hasRange
        ? props.defaultValue || (props as any).value
        : [props.defaultValue || (props as any).value]

    return (
        <StyledSlider {...props} ref={forwardedRef}>
            <SliderTrack>
                <SliderRange />
            </SliderTrack>
            {thumbsArray.map((_: any, i: number) => (
                <SliderThumb key={i} />
            ))}
        </StyledSlider>
    )
})
