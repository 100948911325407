import { Box, keyframes, styled } from 'design-system'
import React from 'react'
const H1 = styled('h1')
const H3 = styled('h3')

const loadingAnimation = keyframes({
    '0%': {
        transform: 'perspective(67.2px) rotateX(-90deg)',
    },

    '50%': {
        transform: 'perspective(67.2px) rotateX(0)',
    },
    '75%': {
        transform: 'perspective(67.2px) rotateX(0)',
    },

    '100%': {
        opacity: 0,
        transform: 'perspective(67.2px) rotateX(0)',
    },
})

const Loading = ({ title, subtitle }) => {
    return (
        <Box
            css={{
                position: 'absolute',
                inset: 0,
                minHeight: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 999999999999999999,
                background: '#fff',
                flexDirection: 'column',
                cursor: 'wait',
                pointerEvents: 'none',
            }}
        >
            <Box
                css={{
                    'width': '67.2px',
                    'height': '67.2px',
                    'display': 'grid',
                    'gridTemplateColumns': 'repeat(3, 1fr)',
                    'gridTemplateRows': 'repeat(3, 1fr)',
                    'div': {
                        animation: `${loadingAnimation} 1.5s calc(var(--delay) * 1s) infinite backwards`,
                        backgroundColor: '#ff3861',
                    },
                    'div:nth-child(1)': {
                        '--delay': 0.1,
                    },
                    'div:nth-child(2)': {
                        '--delay': 0.2,
                    },
                    'div:nth-child(3)': {
                        '--delay': 0.3,
                    },
                    'div:nth-child(4)': {
                        '--delay': 0.4,
                    },
                    'div:nth-child(5)': {
                        '--delay': 0.5,
                    },
                    'div:nth-child(6)': {
                        '--delay': 0.6,
                    },
                    'div:nth-child(7)': {
                        '--delay': 0.7,
                    },
                    'div:nth-child(8)': {
                        '--delay': 0.8,
                    },
                    'div:nth-child(9)': {
                        '--delay': 0.9,
                    },
                }}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </Box>
            <H1
                css={{
                    textAlign: 'center',
                    fontFamily: '$heading',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    mb: '6px',
                    zIndex: 10,
                    mt: 40,
                }}
            >
                {title}
            </H1>
            <H3
                css={{
                    textAlign: 'center',
                    fontFamily: 'regular',
                    fontWeight: 300,
                    fontSize: '14px',
                    mb: 80,
                    zIndex: 10,
                }}
            >
                {subtitle}
            </H3>
        </Box>
    )
}

export default Loading
