import { CSS, styled } from '@stitches/react'
import { ReactNode, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { BottomDialogContent, Dialog } from './Dialog'
import { ErrorBox } from './ErrorBox'
import InApp from './isInApp'
import InfoIcon from './assets/info_circle.svg'
import { Button } from './Button'

export interface DropzoneProps extends DropzoneOptions {
    children?: ReactNode
    css?: CSS
    onError?: (error: Error) => void
    onDrop: any
}

export const Dropzone = ({ children, css, ...props }: DropzoneProps) => {
    const inApp = new InApp()
    const isTargetDevice = inApp.isInApp && inApp.isAndroid
    const shouldShowSingleUploadDialog = isTargetDevice

    // @ts-ignore
    const { getRootProps, getInputProps, open } = useDropzone({
        ...props,
        onDrop: async (acceptedFiles, rejectedFiles, event) => {
            if (shouldShowSingleUploadDialog) {
                await props.onDrop([acceptedFiles[0]], rejectedFiles)
            } else {
                await props.onDrop(acceptedFiles, rejectedFiles, event)
            }
            setIsOpen(false)
        },
        multiple: true,
    })

    const [isOpen, setIsOpen] = useState(false)

    const openDialog = () => {
        setIsOpen(true)
    }

    return (
        <>
            <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
                <BottomDialogContent css={{ background: 'transparent' }}>
                    <ErrorBox isAlert icon={InfoIcon}>
                        Dodaj jednu po jednu sliku!
                    </ErrorBox>
                    <Button
                        {...getRootProps()}
                        onClick={() => {
                            open()
                        }}
                        {...props}
                        css={{ width: '100%', padding: '$3 0', marginTop: '$2' }}
                    >
                        <input {...getInputProps()} />
                        Dodaj sliku
                    </Button>
                </BottomDialogContent>
            </Dialog>
            <DropzoneContainer
                {...getRootProps()}
                onClick={shouldShowSingleUploadDialog ? openDialog : open}
                css={css}
            >
                <input {...getInputProps()} />
                <DropzoneContents>{!!children && children}</DropzoneContents>
            </DropzoneContainer>
        </>
    )
}

const DropzoneContents = styled('div', {
    display: 'flex',
    alignItems: 'center',
    color: '$brand',
    justifyContent: 'center',
})

const DropzoneContainer = styled('div', {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '1px',
    borderRadius: '4px',
    borderStyle: 'dashed',
    color: '$brand',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    backgroundColor: '$brandBackground',
    borderColor: '$brand',
    variants: {
        isFocused: {
            true: {
                color: 'green',
            },
        },
        isDragAccepted: {
            true: {
                color: 'cyan',
            },
        },
    },
})
