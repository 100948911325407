import { Box, styled } from 'design-system'
import * as React from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import {
    DefaultSizes,
    PhotoSize,
    updateSize,
    useAppDispatch,
    useAppState,
} from './AppState'

function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Layout = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const { product } = useParams()
    const { currentScene, error } = useAppState()
    const query = useQuery()

    React.useEffect(() => {
        const predefinedSize = query.get('size')

        if (!product || currentScene.size) return

        if (!predefinedSize) {
            dispatch(updateSize(DefaultSizes[product]))
        }

        if (
            ((predefinedSize === PhotoSize['20x20'] ||
                predefinedSize === PhotoSize['30x30']) &&
                product === 'squaregrid') ||
            ((predefinedSize === PhotoSize['20x30'] ||
                predefinedSize === PhotoSize['30x45']) &&
                product === 'tilegrid')
        ) {
            dispatch(updateSize(predefinedSize))
            return
        }
    }, [product, location])

    return (
        <>
            <Box
                css={{
                    position: 'absolute',
                    zIndex: 999999,
                    top: 70,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'column',
                    backgroundColor: '#ccc',
                }}
            >
                {error.map((e) => {
                    let errorEl = e
                    try {
                    } catch {
                        errorEl = e
                    }
                    return <pre>{e}</pre>
                })}
            </Box>
            <Outlet />
        </>
    )
}

export default Layout
