import { API } from '../library/api'
export class OrderService {
    async placeOrder(payload: any) {
        const response = await API.post('/order', payload)

        return response.data?.data?.id || 0
    }

    async validateCoupon(coupon: string): Promise<number> {
        const response = await API.get(`/coupons/${coupon}`)

        if (!response.data.success) {
            return 0
        }

        return response.data.data.amount / 100
    }
}

export const orderService = new OrderService()
