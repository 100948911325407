import { useParams } from 'react-router-dom'

const Ratios = {
    squaregrid: 1,
    tilegrid: 2 / 3,
} as const

export const usePhotoRatio = () => {
    const { product } = useParams()

    if (!product) return 1

    return Ratios[product]
}
