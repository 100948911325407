import { API } from '../library/api'
import { v4 as uuid } from 'uuid'
import { KEYS, storage } from '../library/storage'
import sha256 from 'crypto-js/sha256'
interface EventPayload {
    eventName: string
    customData?: {
        value?: number
        currency?: string
        content_ids?: string[]
    }
    userData?: {
        email?: string
        phoneNumber?: string
        city?: string
        country?: string
        firstName?: string
        lastName?: string
    }
}

export class TrackingService {
    track(eventName: string, data: any = {}, eventID?: string) {
        window.fbq('track', eventName, data, { eventID })
    }

    async sendEvent({ eventName, customData, userData }: EventPayload) {
        const ip = await this.getIP()
        const eventId = uuid()

        const eventData = {
            event_name: eventName,
            event_time: Math.floor(Date.now() / 1000),
            action_source: 'website',
            event_id: eventId,
            user_data: {
                em: userData?.email
                    ? [sha256(userData?.email.toLowerCase()).toString()]
                    : [],
                ph: userData?.phoneNumber
                    ? [sha256(userData?.phoneNumber).toString()]
                    : [],
                country: [sha256('serbia').toString()],
                fn: userData?.firstName
                    ? [sha256(userData?.firstName.toLowerCase()).toString()]
                    : [],
                ln: userData?.lastName
                    ? [sha256(userData?.lastName.toLowerCase()).toString()]
                    : [],
                ct: userData?.city
                    ? [sha256(userData?.city.toLowerCase()).toString()]
                    : [],
                client_ip_address: ip,
                client_user_agent: navigator.userAgent,
                fbc: this.getCookie('_fbc'),
                fbp: this.getCookie('_fbp'),
            },
            custom_data: {
                currency: customData?.currency,
                value: customData?.value,
            },
        }

        this.track(eventName, customData ? { ...customData } : undefined, eventId)

        return API.post('/event_conversion', {
            data: eventData,
        })
    }

    async pageView() {
        return this.sendEvent({ eventName: 'PageView' })
    }

    async getIP() {
        try {
            const savedIp = storage.load(KEYS.IP)
            const savedIpTimestamp = storage.load(KEYS.IP_TIMESTAMP)

            if (savedIp && savedIpTimestamp + 1000 * 60 * 30 < Date.now()) {
                return savedIp
            }

            const { data: ip } = await API.get('https://api.ipify.org')

            if (!ip) return null

            storage.save(KEYS.IP, ip)
            storage.save(KEYS.IP_TIMESTAMP, Date.now())

            return ip
        } catch {
            return null
        }
    }

    getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop()?.split(';').shift()

        return null
    }
}

export const trackingService = new TrackingService()
