import React from 'react'
import { Box } from '.'
import { styled } from './stitches.config'

interface ErrorBoxProps {
    icon?: string
    isInfo?: boolean
    isAlert?: boolean
    children: React.ReactNode
}

const Img = styled('img')

export const ErrorBox = ({ children, icon, isInfo, isAlert }: ErrorBoxProps) => {
    return (
        <Box
            css={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                background: isInfo
                    ? '$infoBackground'
                    : isAlert
                    ? '#FFF5D2'
                    : '$errorBackground',
                color: isInfo ? '$info' : isAlert ? '#FFA043' : '$error',
                borderRadius: 4,
                fontSize: '12px',
            }}
        >
            {icon && <Img src={icon} css={{ marginRight: '6px' }} />}
            {children}
        </Box>
    )
}
