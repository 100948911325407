import { Box, styled } from 'design-system'
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { trackingService } from '../services/trackingService'

const H1 = styled('h1')
const P = styled('p')

const OrderConfirmed = () => {
    const { orderId } = useParams()
    const location = useLocation()

    useEffect(() => {
        // @ts-ignore
        const price = location?.state?.price
        // @ts-ignore
        const userData = location?.state?.userData

        if (price && userData?.email) {
            trackingService.sendEvent({
                eventName: 'Purchase',
                customData: {
                    value: price,
                    currency: 'RSD',
                },
                userData: {
                    email: userData?.email,
                    phoneNumber: userData?.phoneNumber,
                    city: userData?.city,
                    firstName: userData?.firstName,
                    lastName: userData?.lastName,
                },
            })
        }
    }, [])

    return (
        <Box
            css={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
            }}
        >
            <H1 css={{ fontFamily: '$heading', fontSize: '24px', textAlign: 'center' }}>
                Porudžbina #{orderId} uspešno primljena!
            </H1>
            <P
                as={'a'}
                href="https://photogrid.rs"
                css={{
                    fontFamily: '$regular',
                    fontSize: '14px',
                    textDecoration: 'underline',
                    color: '#000000',
                }}
            >
                Nazad na početnu
            </P>
        </Box>
    )
}

export default OrderConfirmed
