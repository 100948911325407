import { styled } from '@stitches/react'

export const Input = styled('input', {
    'all': 'unset',
    'borderRadius': 4,
    'padding': '14px',
    'boxSizing': 'border-box',
    'width': '100%',
    'fontSize': 16,
    'lineHeight': 1,
    'color': 'black',
    'border': '1px solid',
    'borderColor': '#E2E2E2',
    '&:focus': { borderColor: '$brand' },
    '&:disabled': {
        background: '#f7f7f7',
        color: 'lightgray',
        cursor: 'not-allowed',
    },
    'variants': {
        isError: {
            true: {
                borderColor: '$errorBackground',
            },
        },
    },
})
