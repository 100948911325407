import * as React from 'react'
import { AppProvider, useAppState } from './AppState'
import Home from './pages/Home'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Preview from './pages/Preview'
import CheckoutDialog from './components/CheckoutDialog'
import NoMatch from './pages/NoMatch'
import { globalCss } from 'design-system'
import EditPhotoDialog from './components/EditPhotoDialog'
import OrderConfirmed from './pages/OrderConfirmed'
import SceneSmallBackground from './assets/scene_small.png'
import SceneBigBackground from './assets/scene_big.png'

import { useBeforeUnload } from 'react-use'
import Layout from './Layout'
import Error from './pages/Error'
import Photos from './pages/Photos'
import { trackingService } from './services/trackingService'
import CartDialog from './components/CartDialog'

const globalStyles = globalCss({
    '@import': [
        "url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap')",
        "url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&display=swap')",
    ],

    'html': {
        overflowX: 'hidden',
        boxSizing: 'border-box',
        fontFamily: '$regular',
    },

    'body': {
        margin: 0,
    },

    'html, body': {
        height: '100%',
    },

    '*, *:before, *:after ': {
        boxSizing: 'border-box',
    },

    'svg': { display: 'block' },

    'pre, code': { margin: 0, fontFamily: '$mono' },

    '::selection': {
        backgroundColor: '$violet5',
    },
    '.reactEasyCrop_Container, .reactEasyCrop_CropArea': {
        background: 'none !important',
        border: 'none !important',
        color: 'rgba(0,0,0,0.7) !important',
    },
})

const App = () => {
    const { currentScene, cartScenes } = useAppState()
    React.useEffect(() => {
        new Image().src = SceneSmallBackground
        new Image().src = SceneBigBackground
    }, [])

    useBeforeUnload(currentScene.photos.length > 0 || cartScenes.length > 0, 'STOP')

    return (
        <>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />

                    <Route path=":product" element={<Preview />}>
                        <Route path="edit/:photoIndex" element={<EditPhotoDialog />} />
                        <Route path="checkout" element={<CheckoutDialog />} />
                        <Route path="cart" element={<CartDialog />} />
                    </Route>
                    <Route path=":product/photos" element={<Photos />}>
                        <Route path="edit/:photoIndex" element={<EditPhotoDialog />} />
                    </Route>

                    <Route path="confirmation/:orderId" element={<OrderConfirmed />} />
                    <Route path="error" element={<Error />} />
                </Route>
                <Route path="*" element={<NoMatch />} />
            </Routes>
        </>
    )
}

const BaseApp = () => {
    globalStyles()

    React.useEffect(() => {
        trackingService.getIP()
    }, [])

    return (
        <BrowserRouter>
            <AppProvider>
                <App />
            </AppProvider>
        </BrowserRouter>
    )
}

export default BaseApp
