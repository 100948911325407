import * as React from 'react'

import {
    Box,
    Button,
    Dropzone,
    DropzoneButton,
    DropzoneProps,
    IconButton,
    keyframes,
    styled,
} from 'design-system'
import { v4 as uuid } from 'uuid'
import { addPhotos, useAppDispatch, useAppState } from '../AppState'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom'
import PhotoListItem from '../components/PhotoListItem'
import { getInitialCroppedImage } from 'design-system/PhotoEditor/utils'
import PlusIcon from '../assets/icons/plus.svg'
import PlusBlackIcon from '../assets/icons/plus_black.svg'
import BackIcon from '../assets/icons/chevron_left.svg'
import { usePhotoRatio } from '../hooks'
import Loading from '../components/Loading'
import * as Sentry from '@sentry/react'

type PhotosRouteParams = keyof {
    product: string
}

const P = styled('p')
const scaleUp = keyframes({
    '0%': { transform: 'scale(0.96)' },
    '50%': { transform: 'scale(1.04)' },
    '100%': { transform: 'scale(0.95)' },
})

const Photos = () => {
    const { product } = useParams<PhotosRouteParams>()
    const { currentScene } = useAppState()
    const ratio = usePhotoRatio()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = React.useState(false)

    const onDropPhotos: DropzoneProps['onDrop'] = async (files) => {
        setIsLoading(true)
        try {
            const allPhotos = await Promise.all(
                files.map(async (file) => {
                    const id = uuid()
                    const url = URL.createObjectURL(file)

                    const { croppedImage, cropArea, width, height } =
                        (await getInitialCroppedImage(url, ratio)) as {
                            croppedImage: Blob
                            cropArea: any
                            width: any
                            height: any
                        }

                    return {
                        id,
                        sourceUrl: url,
                        croppedUrl: URL.createObjectURL(croppedImage),
                        croppedBlob: croppedImage,
                        dimensions: {
                            width,
                            height,
                        },
                        file,
                        croppedAreaPixels: cropArea,
                        initialCroppedAreaPixels: cropArea,
                    }
                }),
            )

            dispatch(addPhotos(allPhotos))
            setIsLoading(false)
            navigate(`/${product}`)
        } catch (error) {
            Sentry.captureException(error)
            setIsLoading(false)
        } finally {
        }
    }

    const hasPhotos = currentScene.photos.length > 0

    if (!hasPhotos) {
        return <Navigate to={`/${product}`} />
    }

    return (
        <Box css={{ display: 'flex', flexDirection: 'column' }}>
            <Box
                css={{
                    position: 'sticky',
                    zIndex: 10,
                    top: 0,
                    background: '#fff',
                    boxShadow: '$regular',
                    minHeight: 74,
                }}
            >
                <Box
                    css={{
                        maxWidth: '$mw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minHeight: 74,
                        margin: '0 auto',
                        px: '$3',
                    }}
                >
                    <IconButton icon={BackIcon} onClick={() => navigate(`/${product}`)} />

                    <P css={{ fontSize: 14 }}>
                        Odabrane slike <strong>({currentScene.photos.length})</strong>
                    </P>

                    <DropzoneButton
                        dropzoneOptions={{ onDrop: onDropPhotos, accept: 'image/*' }}
                        variant="ghost"
                        css={{ padding: 0 }}
                    >
                        <img src={PlusBlackIcon} width={24} height={24} />
                    </DropzoneButton>
                </Box>
            </Box>
            <Box
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    maxWidth: '$mw',
                    margin: '0 auto',
                    width: '100%',
                }}
            >
                {isLoading && (
                    <Loading
                        title="Fotke se učitavaju"
                        subtitle="Sačekaj dok pripremimo tvoju galeriju"
                    />
                )}
                <Box
                    css={{
                        'display': 'grid',
                        'gridAutoRows': 'min-content',
                        'gridTemplateColumns': '1fr 1fr 1fr',
                        'gap': '$1',
                        'padding': '$3',
                        'flexGrow': 1,
                        '@abovePhone': {
                            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                        },
                    }}
                >
                    {currentScene.photos.map((photo, index) => (
                        <PhotoListItem photo={photo} index={index} key={photo.id} />
                    ))}
                    <Box css={{ position: 'relative' }}>
                        <Box css={{ opacity: 0 }}>
                            <PhotoListItem photo={currentScene.photos[0]} index={-1} />
                        </Box>

                        <Dropzone
                            onDrop={onDropPhotos}
                            accept="image/*"
                            css={{ position: 'absolute', inset: 0 }}
                        >
                            <img src={PlusIcon} />
                        </Dropzone>
                    </Box>
                </Box>
                <Outlet />
                <Box
                    css={{
                        'position': 'sticky',
                        'width': '100%',
                        'padding': '$3',
                        'bottom': 0,
                        'boxShadow': '$regular',
                        'background': '#fff',
                        '@abovePhone': {
                            display: 'none',
                        },
                    }}
                >
                    <Button
                        variant="primary"
                        css={{ width: '100%', boxSizing: 'border-box' }}
                        onClick={() => {
                            navigate(`/${product}`)
                        }}
                    >
                        Vrati se na galeriju
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const H1 = styled('h1')
const H3 = styled('h3')

export default Photos
