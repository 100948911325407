import * as React from 'react'

import { Box, Button, IconButton } from 'design-system'
import { Photo, removePhoto, useAppDispatch } from '../AppState'
import { Link, useNavigate } from 'react-router-dom'
import { usePhotoRatio } from '../hooks'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import CloseIcon from '../assets/icons/close.svg'
import { isInIframe } from 'design-system/PhotoEditor/utils'

interface Props {
    photo: Photo
    index: number
}

const PhotoListItem = ({ photo, index }: Props) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const editRoute = `edit/${index}`
    const ratio = usePhotoRatio()

    const onRemove = () => {
        dispatch(removePhoto(photo.id))
    }

    return (
        <Box
            css={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '4px',
            }}
        >
            <Box
                css={{
                    width: '100%',
                    borderRadius: '4px',
                    position: 'relative',
                }}
            >
                <AspectRatio.Root ratio={ratio}>
                    <img
                        src={
                            isInIframe()
                                ? 'https://via.placeholder.com/100x100'
                                : photo.croppedUrl
                        }
                        key={photo.id}
                        alt=""
                        width="100%"
                        height={'auto'}
                        style={{ objectFit: 'cover', borderRadius: '4px' }}
                    />
                </AspectRatio.Root>

                <IconButton
                    onClick={onRemove}
                    css={{
                        position: 'absolute',
                        top: '3px',
                        right: '3px',
                        background: '#fff',
                        boxShadow: '$regular',
                    }}
                    icon={CloseIcon}
                />
            </Box>

            <Button as={Link} to={editRoute} variant="action" css={{ mt: '$1' }}>
                Uredi sliku
            </Button>
        </Box>
    )
}

export default PhotoListItem
