export const isInIframe = () => {
    try {
        return window.self !== window.top
    } catch {
        return true
    }
}

export const isIOS = () => {
    return (
        [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
}

export const isMetaBrowser = () => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    return (
        userAgent.indexOf('FBAN') > -1 ||
        userAgent.indexOf('FBAV') > -1 ||
        userAgent.indexOf('Instagram') > -1
    )
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.src = url
    })

export function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180
}
/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
    const rotRad = getRadianAngle(rotation)
    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImage(
    imageSrc: string,
    pixelCrop: any,
    rotation = 0,
    // flip = { horizontal: false, vertical: false },
) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation,
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    // ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
        canvas.toBlob(
            (file) => {
                resolve({
                    croppedImage: file,
                })
            },
            'image/jpeg',
            1,
        )
    })
}

export async function getInitialCroppedImage(imageSrc: string, aspectRatio: number) {
    try {
        const inputImage = await createImage(imageSrc)

        const inputWidth = inputImage.naturalWidth
        const inputHeight = inputImage.naturalHeight
        const inputImageAspectRatio = inputWidth / inputHeight

        // if it's bigger than our target aspect ratio
        let outputWidth = inputWidth
        let outputHeight = inputHeight

        if (inputImageAspectRatio > aspectRatio) {
            outputWidth = inputHeight * aspectRatio
        } else if (inputImageAspectRatio < aspectRatio) {
            outputHeight = inputWidth / aspectRatio
        }

        // calculate the position to draw the image at
        const outputX = (outputWidth - inputWidth) * 0.5
        const outputY = (outputHeight - inputHeight) * 0.5

        // create a canvas that will present the output image
        const outputImage = document.createElement('canvas')

        // set it to the same size as the image
        outputImage.width = outputWidth
        outputImage.height = outputHeight

        // draw our image at position 0, 0 on the canvas
        const ctx = outputImage.getContext('2d')
        if (!ctx) {
            return null
        }
        ctx.drawImage(inputImage, outputX, outputY)

        return new Promise((resolve, reject) => {
            try {
                outputImage.toBlob(
                    (file) => {
                        if (file === null) {
                            reject('file is null')
                        }
                        resolve({
                            croppedImage: file as Blob,
                            cropArea: {
                                width: outputWidth,
                                height: outputHeight,
                                x: Math.abs(outputX) + 1,
                                y: Math.abs(outputY) + 1,
                            },
                            width: inputWidth,
                            height: inputHeight,
                        })
                    },
                    'image/jpeg',
                    1,
                )
            } catch (error) {
                reject(error)
            }
        })
    } catch (error) {
        throw 'error adding photo'
    }
}
