import { useCallback, useEffect, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import { Box, Button, Slider } from '..'
import { getCroppedImage } from './utils'
import { IconButton } from '../IconButton'

import BackIcon from '../assets/chevron_left.svg'
import DoneIcon from '../assets/check.svg'
import { useWindowSize, useUpdate } from 'react-use'
import { Move, Rotate } from '../icons'
import CloseCircleIcon from '../assets/close_circle.svg'
interface Photo {
    id: string
    sourceUrl: string
    croppedUrl: string
    croppedBlob: Blob
    croppedAreaPixels?: any
    initialCroppedAreaPixels?: any
    file: File
    rotation?: number
    dimensions: {
        height: number
        width: number
    }
    debug?: any
}

export interface PhotoEditorProps {
    imageUri: string
    aspect: number | undefined
    onEditComplete: ({
        croppedImage,
        croppedAreaPixels,
        rotation,
        debug,
    }: {
        rotation: number
        croppedImage: Blob
        croppedAreaPixels: any
        debug?: any
    }) => void
    onEditCancel: () => void
    photo: Photo
}

export const PhotoEditor = ({
    imageUri,
    aspect,
    photo,
    onEditComplete,
    onEditCancel,
}: PhotoEditorProps) => {
    const boundingBoxRef = useRef<HTMLDivElement>(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState<number[]>(
        photo?.rotation ? [photo.rotation] : [0],
    )
    const [zoom, setZoom] = useState<number[]>([1])
    const [cropSize, setCropSize] = useState<
        { width: number; height: number } | undefined
    >(undefined)
    const { width: screenWidth } = useWindowSize()
    const update = useUpdate()

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const isVertical = photo.dimensions.height / photo.dimensions.width > 1
    const isPortraitRatio = aspect! < 0.99

    useEffect(() => {
        if (boundingBoxRef.current) {
            setCropSize({
                width: boundingBoxRef.current.clientWidth,
                height: boundingBoxRef.current.clientHeight,
            })
        }
    }, [boundingBoxRef, screenWidth])

    const resetCrop = () => {
        setRotation([0])
        setZoom([1])
    }

    useEffect(() => {
        update()
    }, [boundingBoxRef])

    const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const _onEditComplete = async () => {
        onEditCancel()

        const { croppedImage } = (await getCroppedImage(
            photo?.sourceUrl,
            croppedAreaPixels,
            rotation[0],
        )) as any

        onEditComplete({
            croppedImage,
            croppedAreaPixels,
            rotation: rotation[0],
        })
    }

    return (
        <Box
            css={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box
                css={{
                    height: 60,
                    width: '100%',
                    background: '#ffffff',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    boxShadow: '$regular',
                    px: '$3',
                    zIndex: 1000,
                    fontSize: 14,
                }}
            >
                <IconButton icon={BackIcon} onClick={onEditCancel} />
                <span>Uredi sliku</span>
                <IconButton icon={DoneIcon} onClick={_onEditComplete} />
            </Box>
            <Box
                css={{
                    'maxWidth': isPortraitRatio
                        ? 'calc(60vh * (2/3))'
                        : 'calc(100vw - ((100vw + 206px) - 85vh) - 20px)',
                    'width': '100%',
                    'margin': '0 auto',
                    'background': '#f7f7f7',
                    'flexGrow': 1,
                    '@abovePhone': {
                        width: '100%',
                        maxWidth: 'unset',
                        px: isPortraitRatio ? 100 : 0,
                    },
                }}
            >
                <AspectRatio.Root ratio={aspect}>
                    <Box
                        ref={boundingBoxRef}
                        css={{
                            position: 'relative',
                            background: 'white',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <Cropper
                            image={imageUri}
                            crop={crop}
                            rotation={rotation[0]}
                            zoom={zoom[0]}
                            minZoom={1}
                            maxZoom={5}
                            aspect={aspect}
                            onCropChange={setCrop}
                            initialCroppedAreaPixels={
                                photo?.croppedAreaPixels || undefined
                            }
                            onCropComplete={onCropComplete}
                            onZoomChange={(zoom) => {
                                setZoom([+(Math.round(100 * zoom) / 100).toFixed(2)])
                            }}
                            objectFit={isVertical ? 'horizontal-cover' : 'vertical-cover'}
                        />
                    </Box>
                </AspectRatio.Root>
            </Box>
            <Box
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    justifyContent: 'flex-end',
                }}
            >
                <Box
                    css={{
                        p: '$6',
                        pt: '$3',
                        borderBottom: '1px solid #F6F6F6',
                    }}
                >
                    <Box
                        css={{
                            '@belowPhone': {
                                display: 'none',
                            },
                        }}
                    >
                        <Box
                            css={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mb: '$4',
                            }}
                        >
                            {zoom[0] === 1 ? (
                                <Box
                                    css={{
                                        height: 28.6,
                                        fontWeight: 700,
                                        fontSize: 14,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Zoom
                                </Box>
                            ) : (
                                <Button
                                    onClick={() => {
                                        setZoom([1])
                                    }}
                                    variant={'dark'}
                                    css={{ py: '4px', px: '6px' }}
                                >
                                    {zoom[0]}
                                    <Box
                                        css={{
                                            width: '16px',
                                            height: '17x',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <img src={CloseCircleIcon} alt="" />
                                    </Box>
                                </Button>
                            )}
                        </Box>
                        <Slider
                            defaultValue={[1]}
                            value={zoom}
                            min={1}
                            max={5}
                            step={0.05}
                            aria-labelledby="Zoom"
                            onValueChange={setZoom}
                        />
                    </Box>

                    <Box
                        css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: '$4',
                        }}
                    >
                        {rotation[0] === 0 ? (
                            <Box
                                css={{
                                    height: 28.6,
                                    fontWeight: 700,
                                    fontSize: 14,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Rotacija
                            </Box>
                        ) : (
                            <Button
                                onClick={() => {
                                    setRotation([0])
                                }}
                                variant={'dark'}
                                css={{ py: '4px', px: '6px' }}
                            >
                                {rotation[0]}°
                                <Box
                                    css={{
                                        width: '16px',
                                        height: '17x',
                                        marginLeft: '8px',
                                    }}
                                >
                                    <img src={CloseCircleIcon} alt="" />
                                </Box>
                            </Button>
                        )}
                    </Box>
                    <Slider
                        defaultValue={[0]}
                        value={rotation}
                        min={-180}
                        max={180}
                        step={90}
                        aria-labelledby="Rotate"
                        onValueChange={setRotation}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default PhotoEditor
