import {
    Box,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    styled,
    Text,
} from 'design-system'
import * as React from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FormProvider, useForm } from 'react-hook-form'
import InfoIcon from '../assets/icons/info_circle.svg'

import {
    clearCart,
    clearPhotos,
    PRODUCT_IDS,
    PRODUCT_PRICES,
    useAppDispatch,
    useAppState,
    VARIATION_IDS,
} from '../AppState'
import { fileService } from '../services/fileService'
import { orderService } from '../services/orderService'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Input from './inputs/Input'
import BackIcon from '../assets/icons/chevron_left.svg'
import Loading from './Loading'
import { ErrorBox } from 'design-system'

const Span = styled('span')
const Form = styled('form')
const schema = yup
    .object({
        email: yup
            .string()
            .email('Email adresa nije uneta u ispravnom formatu')
            .required('Email je obavezno polje'),
        fullName: yup.string().required('Ime i prezime je obavezno polje'),
        address: yup.string().required('Adresa je obavezno polje'),
        city: yup.string().required('Grad je obavezno polje'),
        postalCode: yup.string().required('Poštanski broj je obavezno polje'),
        phoneNumber: yup.string().required('Kontakt telefon je obavezno polje'),
        coupon: yup.string().notRequired(),
    })
    .required()

const CheckoutDialog = () => {
    const [couponDiscountAmount, setCouponDiscountAmount] = React.useState<number>(0)
    const navigate = useNavigate()
    const methods = useForm({ resolver: yupResolver(schema) })
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const coupon = methods.watch('coupon')
    const [isOpen, setIsOpen] = React.useState(true)
    const [isSubmittingOrder, setIsSubmittingOrder] = React.useState(false)

    const dispatch = useAppDispatch()
    const {
        currentScene,
        cartScenes,
        shippingPrice,
        cartScenesPrice,
        cartScenesDiscountedPrice,
        cartScenesDiscountAmount,
    } = useAppState()

    const totalCartPhotos = cartScenes.reduce(
        (previous, current) => previous + current.photos.length,
        0,
    )
    const { product } = useParams()

    const onSubmit = async (data) => {
        try {
            setIsSubmitting(true)
            setIsSubmittingOrder(true)

            const lineItemsRequest = cartScenes.map(async (cs) => {
                const images = cs.photos.map((p) => p.croppedBlob)
                const uploadedImages = await fileService.uploadImages(images)
                const productId = PRODUCT_IDS[cs.size!]
                const variationId = VARIATION_IDS[`${cs.size}${cs.frame}`]

                return {
                    product_id: productId,
                    variation_id: variationId,
                    quantity: uploadedImages.length,
                    images: uploadedImages.map((i) => ({
                        size: cs.size,
                        link: i,
                    })),
                }
            })

            const lineItems = await Promise.all(lineItemsRequest)

            const totalPhotos = lineItems.reduce(
                (previous, current) => previous + current.images.length,
                0,
            )

            const discount =
                couponDiscountAmount > 0
                    ? {
                          coupon_lines: [
                              {
                                  code: data.coupon,
                              },
                          ],
                      }
                    : totalPhotos >= 8
                    ? {
                          coupon_lines: [
                              {
                                  code:
                                      totalPhotos >= 16
                                          ? 'Popust na kolicinu 20'
                                          : totalPhotos >= 12
                                          ? 'Popust na kolicinu 15'
                                          : 'Popust na kolicinu 10',
                              },
                          ],
                      }
                    : {}

            const payload = {
                first_name: data.fullName.split(' ')[0],
                last_name: data.fullName.split(' ')[1],
                email: data.email,
                city: data.city,
                postalcode: data.postalCode,
                address: data.address,
                phone: data.phoneNumber,
                country: 'RS',
                line_items: lineItems,
                shipping_lines: shippingPrice,
                ...discount,
            }
            const orderId = await orderService.placeOrder(payload)
            dispatch(clearPhotos())
            dispatch(clearCart())
            setIsOpen(false)
            setIsSubmittingOrder(false)
            setIsSubmitting(false)
            navigate(`/confirmation/${orderId}`, {
                state: {
                    price: totalPriceWithShipping - shippingPrice,
                    userData: {
                        email: data.email,
                        city: data.city,
                        firstName: data.fullName.split(' ')[0],
                        lastName: data.fullName.split(' ')[1],
                        phoneNumber: data.phoneNumber,
                    },
                },
            })
        } catch (error) {
            Sentry.captureException(error, {
                extra: {
                    payload: JSON.stringify(error),
                    response: JSON.stringify(error),
                },
            })
            navigate(`/error`)
        }
    }

    const onValidateCoupon = async () => {
        try {
            setIsSubmitting(true)
            const couponDiscountAmount = await orderService.validateCoupon(coupon)
            if (couponDiscountAmount === 0) {
                methods.setError('coupon', {
                    type: 'custom',
                    message: 'Uneti kupon nije važeci',
                })
            } else {
                methods.clearErrors('coupon')
            }
            setCouponDiscountAmount(couponDiscountAmount)
        } catch {
            methods.setError('coupon', {
                type: 'custom',
                message: 'Uneti kupon nije važeci',
            })
        } finally {
            setIsSubmitting(false)
        }
    }
//Bob was here :)
    const discountValue = 
        couponDiscountAmount === 15 ? 1500 : couponDiscountAmount === 5 ? 500 : cartScenesPrice * couponDiscountAmount;
    const totalPriceWithShipping =
        cartScenesDiscountedPrice - discountValue + shippingPrice

    if (!cartScenes.length) return <Navigate to={`/${product}`} />

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(isOpen) => {
                if (isOpen) return

                navigate(-1)
            }}
        >
            {isSubmittingOrder && (
                <Loading
                    title="Porudžbina se šalje"
                    subtitle="Sačekaj dok se pošalju sve tvoje slike"
                />
            )}
            <DialogContent
                css={{
                    '@abovePhone': {
                        maxHeight: '90vh',
                    },
                    '@subPhone': {
                        pb: 100,
                    },
                }}
            >
                <Box
                    css={{
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        width: '100%',
                        background: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        boxShadow: '$regular',
                        px: '$3',
                        height: 60,
                        fontSize: 14,
                        zIndex: 1000,
                    }}
                >
                    <IconButton icon={BackIcon} onClick={() => navigate(-1)} />
                    <span>Poručivanje</span>
                    <Box css={{ width: 24 }} />
                </Box>
                <Box
                    css={{
                        'p': '$3',
                        'pt': '$5',
                        '@abovePhone': {
                            px: '$5',
                            pt: '$6',
                        },
                    }}
                >
                    <FormProvider {...methods}>
                        <Form
                            onSubmit={methods.handleSubmit(onSubmit)}
                            css={{
                                'display': 'flex',
                                'flexDirection': 'column',
                                'gap': '12px',
                                '@abovePhone': {
                                    flexDirection: 'row',
                                },
                            }}
                        >
                            <Box
                                css={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '12px',
                                }}
                            >
                                <Input
                                    type="email"
                                    name="email"
                                    inputMode="email"
                                    placeholder="Email"
                                    label="Email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <Input
                                    type="text"
                                    placeholder="Ime i prezime"
                                    name="fullName"
                                    label="Ime i prezime"
                                    autoComplete="name"
                                />
                                <Input
                                    type="text"
                                    placeholder="Adresa"
                                    name="address"
                                    label="Adresa za dostavu"
                                    autoComplete="address-line1"
                                />
                                <Input
                                    type="text"
                                    placeholder="Grad"
                                    name="city"
                                    label="Grad"
                                />
                                <Input
                                    type="text"
                                    placeholder="Poštanski broj"
                                    inputMode="numeric"
                                    name="postalCode"
                                    label="Poštanski broj"
                                    autoComplete="postal-code"
                                />
                                <Input
                                    type="tel"
                                    inputMode="tel"
                                    placeholder="Kontakt telefon"
                                    name="phoneNumber"
                                    label="Kontakt telefon"
                                    autoComplete="tel"
                                />
                                {totalCartPhotos < 8 ? (
                                    <Input
                                        type="text"
                                        placeholder="Kupon"
                                        name="coupon"
                                        label="Kupon"
                                        disabled={
                                            isSubmitting || couponDiscountAmount > 0
                                        }
                                    >
                                        <Box>
                                            <Button
                                                onClick={onValidateCoupon}
                                                type="button"
                                                variant={'secondary'}
                                                disabled={
                                                    isSubmitting ||
                                                    !coupon ||
                                                    couponDiscountAmount > 0
                                                }
                                                css={
                                                    couponDiscountAmount > 0
                                                        ? {
                                                              '&:disabled': {
                                                                  background: '#E7F8DE',
                                                                  color: '#85DD58',
                                                              },
                                                          }
                                                        : {}
                                                }
                                            >
                                                {couponDiscountAmount > 0
                                                    ? 'Primenjen kupon'
                                                    : 'Primeni kupon'}
                                            </Button>
                                        </Box>
                                    </Input>
                                ) : (
                                    <Box css={{ marginTop: '6px' }}>
                                        <ErrorBox icon={InfoIcon} isInfo>
                                            Kuponi ne važe za narudžbine koje već imaju
                                            popust na količinu.
                                        </ErrorBox>
                                    </Box>
                                )}
                            </Box>

                            <Box
                                css={{
                                    'display': 'flex',
                                    'flexDirection': 'column',
                                    'gap': '12px',
                                    '@abovePhone': {
                                        flexGrow: 1,
                                    },
                                }}
                            >
                                <Box
                                    css={{
                                        background: '$brandBackground',
                                        borderRadius: '4px',
                                        padding: '18px 16px',
                                        fontSize: '14px',
                                    }}
                                >
                                    <Box
                                        css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexGrow: 1,
                                            marginBottom: '8px',
                                        }}
                                    >
                                        <Span css={{ flexGrow: 1 }}>Slike</Span>
                                        <Span>
                                            {cartScenesPrice?.toLocaleString('sr-RS')} RSD
                                        </Span>
                                    </Box>
                                    {!!cartScenesDiscountAmount && (
                                        <Box
                                            css={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexGrow: 1,
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <Span css={{ flexGrow: 1 }}>Popust</Span>
                                            <Span>
                                                -
                                                {cartScenesDiscountAmount?.toLocaleString(
                                                    'sr-RS',
                                                )}{' '}
                                                RSD
                                            </Span>
                                        </Box>
                                    )}

                                    {Boolean(couponDiscountAmount > 0) && (
                                        <Box
                                            css={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexGrow: 1,
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <Span css={{ flexGrow: 1 }}>
                                                Kupon <strong>{coupon}</strong>
                                            </Span>
                                            <Span>
                                                -{discountValue?.toLocaleString('sr-RS')}{' '}
                                                RSD
                                            </Span>
                                        </Box>
                                    )}
                                    <Box
                                        css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexGrow: 1,
                                            marginBottom: '8px',
                                        }}
                                    >
                                        <Span css={{ flexGrow: 1 }}>Poštarina</Span>
                                        <Span>
                                            {shippingPrice === 0
                                                ? 'BESPLATNA'
                                                : `${shippingPrice} RSD`}
                                        </Span>
                                    </Box>
                                    <Box
                                        css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Span css={{ flexGrow: 1 }}>
                                            <strong>Ukupno</strong>
                                        </Span>
                                        <Span>
                                            <strong>
                                                {totalPriceWithShipping?.toLocaleString(
                                                    'sr-RS',
                                                )}{' '}
                                                RSD
                                            </strong>
                                        </Span>
                                    </Box>
                                </Box>

                                <Button
                                    disabled={isSubmitting}
                                    css={{ width: '100%', boxSizing: 'border-box' }}
                                    autoFocus
                                >
                                    Poruči
                                </Button>
                                <Text
                                    css={{
                                        'color': '#f3f3f3',
                                        '&::selection': {
                                            backgroundColor: '#ccc',
                                        },
                                        'fontSize': 8,
                                        'display': 'inline-block',
                                    }}
                                >
                                    {parseFloat(String(cartScenesPrice / 117.5)).toFixed(
                                        2,
                                    )}
                                </Text>
                            </Box>
                        </Form>
                    </FormProvider>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default CheckoutDialog
