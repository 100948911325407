import React from 'react'
import {
    removeSceneFromCart,
    setCurrentScene,
    useAppDispatch,
    useAppState,
} from '../AppState'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    styled,
    Text,
} from 'design-system'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BackIcon from '../assets/icons/chevron_left.svg'
import CartSceneItem from './CartSceneItem'
import ProductTilePhoto from '../assets/product_tile.png'
import ProductSquarePhoto from '../assets/product_square.png'
import * as Portal from '@radix-ui/react-portal'
import useNextBundleSize from '../hooks/useNextBundleSize'
const Img = styled('img')

const CartDialog = () => {
    const [isOpen, setIsOpen] = React.useState(true)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const nextBundle = useNextBundleSize()
    const { product } = useParams()

    const { totalPhotos, cartScenes } = useAppState()

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(isOpen) => {
                if (isOpen) return

                navigate(-1)
            }}
        >
            <DialogContent
                css={{
                    '@abovePhone': {
                        maxHeight: '90vh',
                    },
                    '@subPhone': {
                        pb: 100,
                    },
                }}
            >
                <Box
                    css={{
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        width: '100%',
                        background: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        boxShadow: '$regular',
                        px: '$3',
                        height: 60,
                        fontSize: 14,
                        zIndex: 1000,
                    }}
                >
                    <IconButton icon={BackIcon} onClick={() => navigate(`/${product}`)} />
                    <span>Moja Korpa</span>
                    <Box css={{ width: 24 }} />
                </Box>
                <Box css={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        css={{
                            'p': '$3',
                            'pt': '$5',
                            'display': 'flex',
                            'flexDirection': 'column',
                            'gap': 12,
                            '@abovePhone': {
                                px: '$5',
                                pt: '$6',
                            },
                        }}
                    >
                        {cartScenes.length === 0 && (
                            <Box
                                css={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    background: '#FFF5F7',
                                    borderRadius: 8,
                                    padding: 16,
                                    gap: 8,
                                }}
                            >
                                <Text>Korpa je prazna.</Text>
                                <Text>Dodaj slike 👇</Text>
                            </Box>
                        )}
                        {cartScenes.map((cs) => (
                            <CartSceneItem key={cs.id} scene={cs} />
                        ))}
                    </Box>

                    {!!totalPhotos && nextBundle?.photosUntilNextBundle && (
                        <Box
                            css={{
                                p: '$3',
                            }}
                        >
                            <Box
                                css={{
                                    height: 48,
                                    width: '100%',
                                    p: '$3',
                                    border: '1px solid #E7F8DE',
                                    borderRadius: '4px',
                                    background: '#F3FDF1',
                                    color: '#00B67A',
                                }}
                            >
                                <Box
                                    css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 10,
                                    }}
                                >
                                    <Text css={{ fontSize: 16 }}>👇</Text>
                                    <Text css={{ fontSize: 12 }}>
                                        Dodaj još {nextBundle?.photosUntilNextBundle}{' '}
                                        slike i uštedi{' '}
                                        <strong>{nextBundle?.nextBundleSale}%</strong>
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    <Box
                        css={{ display: 'flex', alignItems: 'center', gap: 9, px: '$3' }}
                    >
                        <ProductCard type="square" />
                        <ProductCard type="tile" />
                    </Box>
                    {!!cartScenes.length && (
                        <Box css={{ px: '$3', mt: '$4' }}>
                            <Button
                                as={Link}
                                to={`/${product}/checkout`}
                                css={{ width: '100%', boxSizing: 'border-box' }}
                            >
                                Nastavi na Poručivanje
                            </Button>
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const ProductCard = ({ type }: { type: 'square' | 'tile' }) => {
    const isSquare = type === 'square'

    return (
        <Box
            css={{
                display: 'flex',
                flexDirection: 'column',
                background: '#FFF5F7',
                borderRadius: '4px',
                boxSizing: 'border-box',
                p: 8,
                pt: 12,
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Box
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 16,
                    height: 100,
                }}
            >
                <Img
                    src={isSquare ? ProductSquarePhoto : ProductTilePhoto}
                    css={{ width: 61 }}
                />
            </Box>
            <Box css={{ textAlign: 'center', mb: 8 }}>
                <Text css={{ fontSize: 16, fontWeight: 700, fontFamily: '$heading' }}>
                    {isSquare ? 'SquareGrid' : 'TileGrid'}
                </Text>
            </Box>
            <Box css={{ textAlign: 'center', mb: 16 }}>
                <Text css={{ fontSize: 10, fontWeight: 400 }}>
                    {isSquare ? '20x20, 30x30' : '20x30, 30x45'}
                </Text>
            </Box>
            <Button
                css={{ background: '#000', width: '100%', boxSizing: 'border-box' }}
                as={Link}
                to={isSquare ? '/squaregrid' : '/tilegrid'}
            >
                Dodaj
            </Button>
        </Box>
    )
}

export default CartDialog
