import { API } from '../library/api'
import { v4 as uuid } from 'uuid'
export class FileService {
    async uploadImages(images: Blob[]): Promise<string[]> {
        const uploadRequests = images.map((image) => {
            const formData = new FormData()
            formData.append('images[]', image, `${uuid()}.jpeg`)
            return API.postFile('/upload', formData)
        })

        const responses = await Promise.all(uploadRequests)

        return responses.map((response) => response.data.data[0])
    }
}

export const fileService = new FileService()
