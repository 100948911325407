import * as React from 'react'
import { styled, CSS } from './stitches.config'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { overlayStyles } from './Overlay'
import { panelStyles } from './Panel'

export interface DialogProps extends React.ComponentProps<typeof DialogPrimitive.Root> {
    children: React.ReactNode
}

const StyledOverlay = styled(DialogPrimitive.Overlay, overlayStyles, {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'grid',
    placeItems: 'center',
    zIndex: 10000,
})

export function Dialog({ children, ...props }: DialogProps) {
    return (
        <DialogPrimitive.Root {...props}>
            <DialogPrimitive.Portal>
                <StyledOverlay>{children}</StyledOverlay>
            </DialogPrimitive.Portal>
        </DialogPrimitive.Root>
    )
}

const StyledContent = styled(DialogPrimitive.Content, panelStyles, {
    'minWidth': 200,
    // animation: `${fadeIn} 125ms linear, ${moveDown} 125ms cubic-bezier(0.22, 1, 0.36, 1)`,

    // Among other things, prevents text alignment inconsistencies when dialog can't be centered in the viewport evenly.
    // Affects animated and non-animated dialogs alike.
    'willChange': 'transform',
    'borderRadius': '$3',
    '&:focus': {
        outline: 'none',
    },

    /* Works on Firefox */
    'scrollbarWidth': 'thin',
    'scrollbarColor': '$brand #f7f7f7',

    /* Works on Chrome, Edge, and Safari */
    '&::-webkit-scrollbar': {
        width: '6px',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
    },

    '&::-webkit-scrollbar-track': {
        background: '#f7f7f7',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
    },

    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '$brand',
        borderRadius: '20px',
        border: '2px solid #f7f7f7',
    },
    'maxHeight': '80vh',
    'overflowY': 'auto',

    '@subContent': {
        width: '100vw',
        minHeight: '100vh',
        // maxHeight: '100vh',
        // transform: 'none',
        borderRadius: 0,
        // pb: '$4',
    },

    '@abovePhone': {
        width: '700px',
    },
})

type DialogContentPrimitiveProps = React.ComponentProps<typeof DialogPrimitive.Content>
type DialogContentProps = DialogContentPrimitiveProps & { css?: CSS }

export const DialogContent = React.forwardRef<
    React.ElementRef<typeof StyledContent>,
    DialogContentProps
>(({ children, ...props }, forwardedRef) => (
    <StyledContent {...props} ref={forwardedRef}>
        {children}
    </StyledContent>
))

const StyledBottomContent = styled(DialogPrimitive.Content, panelStyles, {
    'minWidth': 200,
    // animation: `${fadeIn} 125ms linear, ${moveDown} 125ms cubic-bezier(0.22, 1, 0.36, 1)`,

    // Among other things, prevents text alignment inconsistencies when dialog can't be centered in the viewport evenly.
    // Affects animated and non-animated dialogs alike.
    'willChange': 'transform',
    'borderRadius': '$3',
    '&:focus': {
        outline: 'none',
    },

    'maxHeight': '80vh',

    '@subContent': {
        width: '95vw',
        // maxHeight: '100vh',
        // transform: 'none',
        borderRadius: 4,
        position: 'fixed',
        bottom: '20px; bottom: calc(env(safe-area-inset-bottom) + 20px);',
        left: '2.5vw',
        // pb: '$4',
    },

    '@abovePhone': {
        width: '700px',
    },
})
export const BottomDialogContent = React.forwardRef<
    React.ElementRef<typeof StyledBottomContent>,
    DialogContentProps
>(({ children, ...props }, forwardedRef) => (
    <StyledBottomContent {...props} ref={forwardedRef}>
        {children}
    </StyledBottomContent>
))

export const DialogTrigger = DialogPrimitive.Trigger
export const DialogClose = DialogPrimitive.Close
export const DialogTitle = DialogPrimitive.Title
export const DialogDescription = DialogPrimitive.Description
