import { Box, styled } from 'design-system'
import React from 'react'

const H1 = styled('h1')
const P = styled('p')

const Error = () => {
    return (
        <Box
            css={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
            }}
        >
            <H1 css={{ fontFamily: '$heading', fontSize: '24px', textAlign: 'center' }}>
                Došlo je do greške
            </H1>

            <P
                css={{
                    fontFamily: '$regular',
                    fontSize: '14px',
                    color: '#000000',
                }}
            >
                Molimo te da nas kontaktiraš putem{' '}
                <P
                    as={'a'}
                    href="https://instagram.com/photogrid.rs"
                    css={{
                        fontFamily: '$regular',
                        fontSize: '14px',
                        textDecoration: 'underline',
                        color: '#000000',
                    }}
                >
                    instagrama
                </P>
            </P>

            <P
                as={'a'}
                href="https://photogrid.rs"
                css={{
                    fontFamily: '$regular',
                    fontSize: '14px',
                    textDecoration: 'underline',
                    color: '#000000',
                }}
            >
                Nazad na početnu
            </P>
        </Box>
    )
}

export default Error
