import {
    BottomDialogContent,
    Box,
    Button,
    Dialog,
    Dropzone,
    DropzoneButton,
    DropzoneProps,
    IconButton,
    RadioGroup,
    Separator,
    styled,
    Text,
} from 'design-system'
import * as React from 'react'
import * as Sentry from '@sentry/react'

import { v4 as uuid } from 'uuid'
import {
    Link as RRLink,
    Navigate,
    Outlet,
    useNavigate,
    useParams,
} from 'react-router-dom'
import {
    addPhotos,
    defaultAppState,
    DefaultSizes,
    PhotoFrame,
    PhotoFrameType,
    PhotoSize,
    PhotoSizeType,
    PRODUCT_PRICES,
    removePhoto,
    SALE_ACTIVE,
    updateFrame,
    updateSize,
    useAppDispatch,
    useAppState,
    SALE_END_DATE,
    addSceneToCart,
    setError,
    Photo,
} from '../AppState'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import { getTitleCase } from '../utils'
import { usePhotoRatio } from '../hooks'
import { useUpdate, useWindowSize } from 'react-use'
import OptionsPicker, { PickerItem } from '../components/OptionsPicker'

import FrameNone from '../assets/frame_none.png'
import FrameBlack from '../assets/frame_black.png'
import FrameWhite from '../assets/frame_white.png'
import CartIcon from '../assets/icons/cart.svg'
import FrameMixed from '../assets/frame_mixed.png'
import PlusIcon from '../assets/icons/plus.svg'
import BackIcon from '../assets/icons/chevron_left.svg'
import SceneBigBackground from '../assets/scene_big.png'
import SceneSmallBackground from '../assets/scene_small.png'
import { getInitialCroppedImage } from 'design-system/PhotoEditor/utils'
import Loading from '../components/Loading'
import { trackingService } from '../services/trackingService'
import { useCountdown } from '../hooks/useCountdown'
import useActiveBundleSize from '../hooks/useActiveBundleSize'
import useNextBundleSize from '../hooks/useNextBundleSize'
import InApp from './isInApp'

const Img = React.memo(styled('img'))
const Link = styled(RRLink)

const PhotoItem = ({ photo, frame, index }) => {
    const hasBorder = frame !== PhotoFrame.None
    const isBorderBW = frame === PhotoFrame.BlackAndWhite
    const photoRatio = usePhotoRatio()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)

    if (!photo)
        return (
            <Box
                css={{
                    padding: hasBorder
                        ? isBorderBW
                            ? 'calc(100% * 0.05)'
                            : 'calc(100% * 0.1)'
                        : 0,
                    background: isBorderBW ? 'black' : `${frame}`,
                    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
                }}
            >
                <Box
                    css={{
                        padding: isBorderBW ? 'calc(100% * 0.05)' : 0,
                        background: 'white',
                    }}
                >
                    <AspectRatio.Root ratio={photoRatio}>
                        <Img
                            src={SceneSmallBackground}
                            alt=""
                            css={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </AspectRatio.Root>
                </Box>
            </Box>
        )

    return (
        <>
            <Box
                css={{
                    padding: hasBorder
                        ? isBorderBW
                            ? 'calc(100% * 0.05)'
                            : 'calc(100% * 0.1)'
                        : 0,
                    background: isBorderBW ? 'black' : `${frame}`,
                    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
                }}
                onClick={() => {
                    setIsDialogOpen(true)
                }}
                key={photo.id}
            >
                <Box
                    css={{
                        padding: isBorderBW ? 'calc(100% * 0.05)' : 0,
                        background: 'white',
                    }}
                >
                    <AspectRatio.Root ratio={photoRatio}>
                        <Img
                            src={photo.croppedUrl}
                            alt=""
                            css={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </AspectRatio.Root>
                </Box>
            </Box>
            <Dialog
                onOpenChange={(isOpen) => {
                    setIsDialogOpen(isOpen)
                }}
                open={isDialogOpen}
            >
                <BottomDialogContent>
                    <Box css={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            onClick={() => {
                                navigate(`edit/${index}`)
                                setIsDialogOpen(false)
                            }}
                            variant={'ghostUnfocusable'}
                            css={{ color: 'black' }}
                        >
                            Uredi sliku
                        </Button>
                        <Separator />
                        <Button
                            onClick={() => {
                                dispatch(removePhoto(photo.id))
                            }}
                            variant={'ghostUnfocusable'}
                            css={{ color: '#f76659' }}
                        >
                            Obriši sliku
                        </Button>
                    </Box>
                </BottomDialogContent>
            </Dialog>
        </>
    )
}

const PhotoItemMemo = React.memo(PhotoItem)
const P = styled('p')

const Preview = () => {
    const update = useUpdate()
    const {
        currentScene,
        activeSale,
        currentScenePrice,
        currentSceneDiscountedPrice,
        cartScenes,
    } = useAppState()

    // const sale = useSaleAmount()
    const ratio = usePhotoRatio()
    const { width: screenWidth } = useWindowSize()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [isLoading, setIsLoading] = React.useState(false)
    const { product } = useParams()
    const [days, hours, minutes, seconds] = useCountdown(SALE_END_DATE)
    const editableAreaRef = React.useRef<HTMLDivElement>(null)

    const [editableAreaWidth, setEditableAreaWidth] = React.useState(0)

    React.useEffect(() => {
        trackingService.pageView()
    }, [])

    React.useEffect(() => {
        if (editableAreaRef.current) {
            setEditableAreaWidth(editableAreaRef.current.clientWidth)
        }
    }, [editableAreaRef, screenWidth])

    React.useEffect(() => {
        if (!product) return
        dispatch(updateSize(DefaultSizes[product]))
    }, [product, location])

    React.useEffect(() => {
        update()
    }, [editableAreaRef])

    // FB Pixel Events
    React.useEffect(() => {
        if (
            !currentScene.frame ||
            !currentScene.size ||
            !product ||
            !currentScene.photos.length
        )
            return

        if (
            product === 'squaregrid' &&
            currentScene.size === PhotoSize['30x30'] &&
            currentScene.frame === PhotoFrame.White
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['square-grid-30-30-white_3589'],
                },
            })
        }
        if (
            product === 'squaregrid' &&
            currentScene.size === PhotoSize['30x30'] &&
            currentScene.frame === PhotoFrame.None
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['square-grid-30-30-full_3588'],
                },
            })
        }
        if (
            product === 'squaregrid' &&
            currentScene.size === PhotoSize['30x30'] &&
            currentScene.frame === PhotoFrame.Black
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['square-grid-30-30-black_3587'],
                },
            })
        }
        if (
            product === 'squaregrid' &&
            currentScene.size === PhotoSize['30x30'] &&
            currentScene.frame === PhotoFrame.BlackAndWhite
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['square-grid-30-30-mix_3186'],
                },
            })
        }

        if (
            product === 'squaregrid' &&
            currentScene.size === PhotoSize['20x20'] &&
            currentScene.frame === PhotoFrame.White
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['square-grid-20-20-white_3187'],
                },
            })
        }
        if (
            product === 'squaregrid' &&
            currentScene.size === PhotoSize['20x20'] &&
            currentScene.frame === PhotoFrame.None
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['square-grid-20-20-full_3579'],
                },
            })
        }
        if (
            product === 'squaregrid' &&
            currentScene.size === PhotoSize['20x20'] &&
            currentScene.frame === PhotoFrame.Black
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['square-grid-20-20-black_3580'],
                },
            })
        }
        if (
            product === 'squaregrid' &&
            currentScene.size === PhotoSize['20x20'] &&
            currentScene.frame === PhotoFrame.BlackAndWhite
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['square-grid-20-20-mix_3581'],
                },
            })
        }

        if (
            product === 'tilegrid' &&
            currentScene.size === PhotoSize['20x30'] &&
            currentScene.frame === PhotoFrame.White
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['tile-grid-20-30-white_3590'],
                },
            })
        }
        if (
            product === 'tilegrid' &&
            currentScene.size === PhotoSize['20x30'] &&
            currentScene.frame === PhotoFrame.None
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['tile-grid-20-30-full_3592'],
                },
            })
        }
        if (
            product === 'tilegrid' &&
            currentScene.size === PhotoSize['20x30'] &&
            currentScene.frame === PhotoFrame.Black
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['tile-grid-20-30-black_3591'],
                },
            })
        }
        if (
            product === 'tilegrid' &&
            currentScene.size === PhotoSize['20x30'] &&
            currentScene.frame === PhotoFrame.BlackAndWhite
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['tile-grid-20-30-mix_3283'],
                },
            })
        }

        if (
            product === 'tilegrid' &&
            currentScene.size === PhotoSize['30x45'] &&
            currentScene.frame === PhotoFrame.White
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['tile-grid-30-45-white_3593'],
                },
            })
        }
        if (
            product === 'tilegrid' &&
            currentScene.size === PhotoSize['30x45'] &&
            currentScene.frame === PhotoFrame.None
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['tile-grid-30-45-full_3595'],
                },
            })
        }
        if (
            product === 'tilegrid' &&
            currentScene.size === PhotoSize['30x45'] &&
            currentScene.frame === PhotoFrame.Black
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['tile-grid-30-45-black_3594'],
                },
            })
        }
        if (
            product === 'tilegrid' &&
            currentScene.size === PhotoSize['30x45'] &&
            currentScene.frame === PhotoFrame.BlackAndWhite
        ) {
            trackingService.sendEvent({
                eventName: 'ViewContent',
                customData: {
                    value:
                        currentScene.photos.length * PRODUCT_PRICES[currentScene.size!],
                    currency: 'RSD',
                    content_ids: ['tile-grid-30-45-mix_3282'],
                },
            })
        }
    }, [currentScene, product])

    const maxPhotoColumns = React.useMemo(() => {
        switch (currentScene.size) {
            case PhotoSize['20x20']:
                return currentScene.photos.length + 1 <= 9 ? 3 : 7
            case PhotoSize['30x30']:
                return currentScene.photos.length + 1 <= 6 ? 3 : 7
            case PhotoSize['20x30']:
                return currentScene.photos.length + 1 <= 6 ? 3 : 7
            case PhotoSize['30x45']:
                return currentScene.photos.length + 1 <= 3 ? 3 : 7
            default:
                return 6
        }
    }, [product, currentScene])
    const isBigScene = maxPhotoColumns >= 7

    const photoWidthMultiplier = React.useMemo(() => {
        switch (currentScene.size) {
            case PhotoSize['20x20']:
            case PhotoSize['20x30']:
                return isBigScene ? 0.07222222222 : 0.16111111111111
            case PhotoSize['30x30']:
            case PhotoSize['30x45']:
                return isBigScene ? 0.11833333333 : 0.24166666666
            default:
                return 0.16111111111111
        }
    }, [currentScene, product, maxPhotoColumns])

    const inApp = new InApp()
    const isTargetDevice = inApp.isInApp && inApp.isAndroid
    const shouldShowSingleUploadDialog = isTargetDevice

    const onDropPhotos: DropzoneProps['onDrop'] = async (files) => {
        setIsLoading(true)
        try {
            const allPhotos = await Promise.all(
                files.map(async (file) => {
                    try {
                        const id = uuid()

                        const url = URL.createObjectURL(file)

                        const initialImage = await getInitialCroppedImage(url, ratio)
                        const { croppedImage, cropArea, width, height } =
                            initialImage as any

                        return {
                            id,
                            sourceUrl: url,
                            croppedUrl: URL.createObjectURL(croppedImage),
                            croppedBlob: croppedImage,
                            dimensions: {
                                width,
                                height,
                            },
                            file,
                            croppedAreaPixels: cropArea,
                            initialCroppedAreaPixels: cropArea,
                        }
                    } catch (error) {
                        dispatch(setError(error))
                    }
                }),
            )

            dispatch(addPhotos(allPhotos as Photo[]))
            setIsLoading(false)
        } catch (error) {
            console.error(error)
            dispatch(setError(error))
            Sentry.captureException(error)
            setIsLoading(false)
        } finally {
        }
    }

    if (product !== 'squaregrid' && product !== 'tilegrid') {
        return <Navigate to="/" />
    }

    return (
        <Box css={{ display: 'flex', flexDirection: 'column' }}>
            <Box
                css={{
                    'position': 'sticky',
                    'zIndex': 10,
                    'top': 0,
                    'background': '#fff',
                    'boxShadow': '$regular',
                    'minHeight': 74,
                    'marginBottom': '24px',
                    '@belowPhone': {
                        display: 'none',
                    },
                }}
            >
                <Box
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        maxWidth: '$mw',
                        width: '100%',
                        margin: '0 auto',
                        px: '$3',
                        minHeight: 74,
                    }}
                >
                    <IconButton
                        icon={BackIcon}
                        onClick={() =>
                            (window.location.href = 'https://photogrid.rs/kategorije/')
                        }
                    />

                    {/* {!!currentScene.photos.length && (
                        <P
                            css={{ fontSize: 14, textDecoration: 'none', color: '#000' }}
                            as={Link}
                            to={`/${product}/photos`}
                        >
                            Odabrane slike <strong>({currentScene.photos.length})</strong>
                        </P>
                    )} */}
                    <P
                        css={{
                            fontSize: 14,
                            textDecoration: 'none',
                            color: '#000',
                            position: 'relative',
                        }}
                        as={Link}
                        to={`/${product}/cart`}
                    >
                        <img src={CartIcon} />
                        {!!cartScenes.length && (
                            <P
                                as="span"
                                css={{
                                    backgroundColor: '$brand',
                                    borderRadius: 100,
                                    position: 'absolute',
                                    width: 14,
                                    height: 14,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    top: -4,
                                    right: -6,
                                    fontSize: 8,
                                    color: '#fff',
                                }}
                            >
                                {cartScenes.length}
                            </P>
                        )}
                    </P>
                </Box>
            </Box>
            <Box
                css={{
                    'display': 'flex',
                    'flex': 1,
                    'flexDirection': 'column',
                    'minHeight': '100vh',
                    'maxWidth': '$mw',
                    'width': '100%',
                    'margin': '0 auto',
                    '@abovePhone': {
                        flexDirection: 'row',
                        px: '$3',
                    },
                }}
            >
                <Box
                    css={{
                        'position': 'sticky',
                        'zIndex': 10,
                        'top': 0,
                        'background': '#fff',
                        'display': 'flex',
                        'alignItems': 'center',
                        'boxShadow': '$regular',
                        'justifyContent': 'space-between',
                        'px': '$3',
                        'minHeight': 60,
                        '@abovePhone': {
                            display: 'none',
                        },
                    }}
                >
                    <IconButton
                        icon={BackIcon}
                        onClick={() =>
                            (window.location.href = 'https://photogrid.rs/kategorije/')
                        }
                    />
                    {/* {!!currentScene.photos.length && (
                        <P
                            css={{ fontSize: 14, textDecoration: 'none', color: '#000' }}
                            as={Link}
                            to={`/${product}/photos`}
                        >
                            Odabrane slike <strong>({currentScene.photos.length})</strong>
                        </P>
                    )} */}
                    <P
                        css={{
                            fontSize: 14,
                            textDecoration: 'none',
                            color: '#000',
                            position: 'relative',
                        }}
                        as={Link}
                        to={`/${product}/cart`}
                    >
                        <img src={CartIcon} />
                        {!!cartScenes.length && (
                            <P
                                as="span"
                                css={{
                                    backgroundColor: '$brand',
                                    borderRadius: 100,
                                    position: 'absolute',
                                    width: 14,
                                    height: 14,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    top: -4,
                                    right: -6,
                                    fontSize: 8,
                                    color: '#fff',
                                }}
                            >
                                {cartScenes.length}
                            </P>
                        )}
                    </P>
                </Box>
                {SALE_ACTIVE && (
                    <Box
                        css={{
                            'display': 'flex',
                            'alignItems': 'center',
                            'justifyContent': 'center',
                            'color': '#fff',
                            'background': '#000',
                            'fontSize': 12,
                            'height': 32,
                            '@abovePhone': {
                                display: 'none',
                            },
                        }}
                    >
                        🔥 Novogodišnja akcija se završava za:&nbsp;
                        <strong>
                            {days >= 1
                                ? `${days} DAN${days === 1 ? '' : 'A'}`
                                : `${hours}:${minutes}:${seconds}`}
                        </strong>
                        &nbsp;🔥
                    </Box>
                )}
                {isLoading && (
                    <Loading
                        title="Fotke se učitavaju"
                        subtitle="Sačekaj dok pripremimo tvoju galeriju"
                    />
                )}
                <Box css={{ width: '100%', maxWidth: '550px' }}>
                    <AspectRatio.Root ratio={0.9}>
                        <Box
                            css={{
                                'width': '100%',
                                'height': '100%',
                                'position': 'relative',
                                '@abovePhone': {
                                    borderRadius: 8,
                                    overflow: 'hidden',
                                },
                            }}
                        >
                            <Box
                                css={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box
                                    ref={editableAreaRef}
                                    css={{
                                        width: '100%',
                                        height: '100%',
                                        flexGrow: 1,
                                        display: 'flex',
                                        margin: '0 auto',
                                        marginTop: isBigScene ? '-80px' : '-60px',
                                        pointerEvents: 'none',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        css={{
                                            'display': 'grid',
                                            'pointerEvents': 'all',
                                            'gridTemplateColumns': `repeat(${
                                                currentScene.photos.length <
                                                maxPhotoColumns
                                                    ? currentScene.photos.length !== 0
                                                        ? currentScene.photos.length
                                                        : 1
                                                    : maxPhotoColumns
                                            }, calc(${
                                                editableAreaRef?.current
                                                    ? editableAreaWidth
                                                    : 300
                                            }px * ${photoWidthMultiplier} - ($1 * ${
                                                (currentScene.photos.length <
                                                maxPhotoColumns
                                                    ? currentScene.photos.length
                                                    : maxPhotoColumns) - 1
                                            }) / ${
                                                currentScene.photos.length <
                                                maxPhotoColumns
                                                    ? currentScene.photos.length !== 0
                                                        ? currentScene.photos.length
                                                        : 1
                                                    : maxPhotoColumns
                                            }))`,
                                            'gridTemplateRows': `repeat(${Math.ceil(
                                                (currentScene.photos.length + 0.1) / 5,
                                            )}, fit-content(200px))`,
                                            'gap': '$1',
                                            '@abovePhone': {
                                                mt:
                                                    currentScene.photos.length > 6
                                                        ? -(
                                                              Math.ceil(
                                                                  (currentScene.photos
                                                                      .length +
                                                                      1) /
                                                                      maxPhotoColumns,
                                                              ) *
                                                                  ((editableAreaRef?.current
                                                                      ? editableAreaWidth
                                                                      : 300) *
                                                                      photoWidthMultiplier) *
                                                                  (product ===
                                                                  'squaregrid'
                                                                      ? 1
                                                                      : 1.5) -
                                                              30
                                                          )
                                                        : -100,
                                            },
                                        }}
                                    >
                                        {currentScene.photos.map((photo, index) => (
                                            <PhotoItemMemo
                                                photo={photo}
                                                index={index}
                                                frame={currentScene.frame}
                                                key={photo.id}
                                            />
                                        ))}
                                        <Box
                                            css={{
                                                position: 'relative',
                                            }}
                                        >
                                            <Box css={{ opacity: 0 }}>
                                                <PhotoItemMemo
                                                    photo={null}
                                                    index={-1}
                                                    frame={currentScene.frame}
                                                />
                                            </Box>

                                            <Dropzone
                                                onDrop={onDropPhotos}
                                                accept="image/*"
                                                css={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    zIndex: 99,
                                                }}
                                            >
                                                <img
                                                    src={PlusIcon}
                                                    width="90%"
                                                    height="90%"
                                                />
                                            </Dropzone>
                                        </Box>
                                    </Box>
                                </Box>
                                <OptionsPicker />
                            </Box>
                            <Img
                                src={
                                    isBigScene ? SceneBigBackground : SceneSmallBackground
                                }
                                css={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: isBigScene
                                        ? 'unset'
                                        : 'center bottom',
                                }}
                            />
                        </Box>
                    </AspectRatio.Root>
                </Box>

                <Box
                    css={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    {SALE_ACTIVE && (
                        <Box
                            css={{
                                'p': 0,
                                '@abovePhone': {
                                    px: 16,
                                },
                            }}
                        >
                            <Box
                                css={{
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'justifyContent': 'center',
                                    'color': '#fff',
                                    'background': '#000',
                                    'fontSize': 12,
                                    'height': 32,
                                    'borderRadius': 4,
                                    'mb': 5,
                                    '@belowPhone': {
                                        display: 'none',
                                    },
                                }}
                            >
                                🔥 Novogodišnja akcija se završava za:&nbsp;
                                <strong>
                                    {days >= 1
                                        ? `${days} DAN${days === 1 ? '' : 'A'}`
                                        : `${hours}:${minutes}:${seconds}`}
                                </strong>
                                &nbsp;🔥
                            </Box>
                        </Box>
                    )}
                    <Box
                        css={{
                            'p': 0,
                            '@abovePhone': {
                                px: 16,
                            },
                        }}
                    >
                        <Box
                            css={{
                                'backgroundColor': '#00B67A',
                                'height': 32,
                                'color': '#ffffff',
                                'fontSize': 12,
                                'display': 'flex',
                                'alignItems': 'center',
                                'justifyContent': 'center',
                                '@abovePhone': {
                                    borderRadius: 4,
                                },
                            }}
                        >
                            🚚 Poštarina je besplatna za porudžbine preko 4.000 RSD
                        </Box>
                    </Box>

                    <Box
                        css={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            p: '16px',
                            pt: '22px',
                        }}
                    >
                        <Box
                            css={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text
                                css={{
                                    fontFamily: '$heading',
                                    fontWeight: 700,
                                    fontSize: '24px',
                                    my: 0,
                                    lineHeight: '32px',
                                    marginBottom: '3px',
                                }}
                            >
                                <span>{`${getTitleCase(product!)}`}</span> -{' '}
                                <span>{currentScene.size}</span>
                            </Text>
                            {!!activeSale && (
                                <Box
                                    css={{
                                        height: 28,
                                        width: 61,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: 12,
                                        color: '#FFA043',
                                        background: '#FFF5D2',
                                        borderRadius: 4,
                                        fontFamily: '$heading',
                                    }}
                                >
                                    🔥 -{activeSale}%
                                </Box>
                            )}
                        </Box>
                        <Box
                            css={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: '24px',
                            }}
                        >
                            <Text
                                css={{
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                <>
                                    {!currentScene.photos.length ? (
                                        <>
                                            <Text
                                                css={{
                                                    textDecoration: !!activeSale
                                                        ? 'line-through'
                                                        : 'none',
                                                }}
                                            >
                                                {PRODUCT_PRICES[
                                                    currentScene.size!
                                                ]?.toLocaleString('sr-RS')}
                                                &nbsp;RSD
                                            </Text>
                                            &nbsp;
                                            {!!activeSale && (
                                                <Text css={{ color: '#FF3861' }}>
                                                    {Number(
                                                        Math.floor(
                                                            PRODUCT_PRICES[
                                                                currentScene.size!
                                                            ] -
                                                                PRODUCT_PRICES[
                                                                    currentScene.size!
                                                                ] *
                                                                    (activeSale / 100),
                                                        ),
                                                    )?.toLocaleString('sr-RS')}
                                                    &nbsp;RSD
                                                </Text>
                                            )}
                                        </>
                                    ) : !!activeSale ? (
                                        <>
                                            <Text
                                                css={{ textDecoration: 'line-through' }}
                                            >
                                                {Math.floor(
                                                    currentScenePrice,
                                                ).toLocaleString('sr-RS')}
                                                &nbsp;RSD
                                            </Text>
                                            &nbsp;
                                            <Text css={{ color: '#FF3861' }}>
                                                {currentSceneDiscountedPrice.toLocaleString(
                                                    'sr-RS',
                                                )}
                                                &nbsp;RSD
                                            </Text>
                                        </>
                                    ) : (
                                        <>
                                            <span>
                                                {String(Math.floor(currentScenePrice))}
                                            </span>
                                            {' RSD / '}
                                            <span>
                                                {String(currentScene.photos.length)}
                                            </span>
                                            {' x '}
                                            <span>
                                                {PRODUCT_PRICES[
                                                    currentScene.size!
                                                ]?.toLocaleString('sr-RS')}
                                            </span>
                                            {' RSD'}
                                        </>
                                    )}
                                </>
                            </Text>
                        </Box>

                        <Text
                            css={{
                                fontSize: '13px',
                                fontWeight: 300,
                                mb: 20,
                                display: 'flex',
                                alignItems: 'center',
                                maxWidth: 768 / 2,
                            }}
                        >
                            Photogrid slike se lepe na zid i bez bušenja i oštećenja možeš
                            da napraviš zidnu galeriju. Samo dodaj slike sa svog uređaja,
                            a mi ćemo ti dostaviti sve na kućnu adresu.
                        </Text>
                        <Text
                            css={{
                                'color': '#fff',
                                '&::selection': {
                                    backgroundColor: '#fff',
                                },
                            }}
                        >
                            {Math.floor(currentScenePrice / 117.5)}
                        </Text>

                        <Box
                            css={{
                                'display': 'none',
                                '@abovePhone': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                },
                            }}
                        >
                            <>
                                <Text
                                    css={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        mb: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Stil okvira
                                </Text>
                                <RadioGroup
                                    defaultValue={
                                        currentScene.frame || defaultAppState.frame
                                    }
                                    aria-label="Okvir"
                                    onValueChange={(value: PhotoFrameType) =>
                                        dispatch(updateFrame(value))
                                    }
                                    css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        gap: 8,
                                        mb: 24,
                                    }}
                                >
                                    <PickerItem
                                        value={PhotoFrame.None}
                                        imageUrl={FrameNone}
                                        isSelected={
                                            currentScene.frame === PhotoFrame.None
                                        }
                                    />
                                    <PickerItem
                                        value={PhotoFrame.White}
                                        imageUrl={FrameWhite}
                                        isSelected={
                                            currentScene.frame === PhotoFrame.White
                                        }
                                    />
                                    <PickerItem
                                        value={PhotoFrame.Black}
                                        imageUrl={FrameBlack}
                                        isSelected={
                                            currentScene.frame === PhotoFrame.Black
                                        }
                                    />
                                    <PickerItem
                                        value={PhotoFrame.BlackAndWhite}
                                        imageUrl={FrameMixed}
                                        isSelected={
                                            currentScene.frame ===
                                            PhotoFrame.BlackAndWhite
                                        }
                                    />
                                </RadioGroup>
                                <Text
                                    css={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        mb: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Veličina slika
                                </Text>
                                <RadioGroup
                                    defaultValue={
                                        currentScene.size || DefaultSizes[product!]
                                    }
                                    aria-label="Velicina"
                                    onValueChange={(value: PhotoSizeType) =>
                                        dispatch(updateSize(value))
                                    }
                                    css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        gap: 8,
                                    }}
                                >
                                    {product === 'squaregrid' ? (
                                        <React.Fragment>
                                            <PickerItem
                                                value={PhotoSize['20x20']}
                                                isSelected={
                                                    currentScene.size ===
                                                    PhotoSize['20x20']
                                                }
                                            >
                                                20x20
                                            </PickerItem>
                                            <PickerItem
                                                value={PhotoSize['30x30']}
                                                isSelected={
                                                    currentScene.size ===
                                                    PhotoSize['30x30']
                                                }
                                            >
                                                30x30
                                            </PickerItem>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <PickerItem
                                                value={PhotoSize['20x30']}
                                                isSelected={
                                                    currentScene.size ===
                                                    PhotoSize['20x30']
                                                }
                                            >
                                                20x30
                                            </PickerItem>
                                            <PickerItem
                                                value={PhotoSize['30x45']}
                                                isSelected={
                                                    currentScene.size ===
                                                    PhotoSize['30x45']
                                                }
                                            >
                                                30x45
                                            </PickerItem>
                                        </React.Fragment>
                                    )}
                                </RadioGroup>
                            </>
                            {!!currentScene.photos.length ? (
                                <Button
                                    onClick={async () => {
                                        await trackingService.sendEvent({
                                            eventName: 'InitiateCheckout',
                                            customData: {
                                                value: currentSceneDiscountedPrice,
                                                currency: 'RSD',
                                            },
                                        })
                                        dispatch(addSceneToCart())
                                        navigate(`/${product}/cart`)
                                    }}
                                    variant="primary"
                                    css={{
                                        maxWidth: '206px',
                                        boxSizing: 'border-box',
                                        mt: 60,
                                    }}
                                >
                                    {currentScene?.isEditing
                                        ? 'Sačuvaj izmene'
                                        : 'Dodaj u korpu'}
                                </Button>
                            ) : (
                                <DropzoneButton
                                    onError={(error) => {
                                        dispatch(setError(error))
                                    }}
                                    dropzoneOptions={{
                                        onDrop: onDropPhotos,
                                        accept: 'image/*',
                                    }}
                                    variant="primary"
                                    css={{
                                        maxWidth: '206px',
                                        boxSizing: 'border-box',
                                        mt: 60,
                                    }}
                                >
                                    Dodaj slike
                                </DropzoneButton>
                            )}
                        </Box>
                    </Box>
                    <Outlet />
                </Box>
                <Box
                    css={{
                        'position': 'sticky',
                        'width': '100%',
                        'padding': '$3',
                        'bottom': 0,
                        'boxShadow': '$regular',
                        'background': '#fff',
                        '@abovePhone': {
                            display: 'none',
                        },
                    }}
                >
                    {!!currentScene.photos.length ? (
                        <Button
                            variant="primary"
                            css={{ width: '100%', boxSizing: 'border-box' }}
                            onClick={() => {
                                dispatch(addSceneToCart())

                                navigate(`/${product}/cart`)
                            }}
                        >
                            {currentScene?.isEditing ? 'Sačuvaj izmene' : 'Dodaj u korpu'}
                        </Button>
                    ) : (
                        <DropzoneButton
                            onError={(error) => {
                                dispatch(setError(error))
                            }}
                            dropzoneOptions={{ onDrop: onDropPhotos, accept: 'image/*' }}
                            variant="primary"
                            css={{ width: '100%', boxSizing: 'border-box' }}
                        >
                            Dodaj slike
                        </DropzoneButton>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default Preview
