import { keyframes, styled } from './stitches.config'

const spin = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
})

export const Progress = styled('div', {
    width: '1em',
    height: '1em',
    border: '5px solid #FFF',
    borderBbottomColor: 'transparent',
    borderRadius: '50%',
    display: 'inline-block',
    animation: `${spin} 1s linear infinite`,
})
