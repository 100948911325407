import { API_BASE_URL } from './constants'
import * as Sentry from '@sentry/react'
import { Base64 } from 'js-base64'
import Axios from 'axios'

export async function errorHandler(error: any) {
    try {
        const dataResponse = Base64.encode(JSON.stringify(error?.response?.data))
        const reqData = error.response?.config?.data
        const dataPayload = Base64.encode(reqData)

        console.debug('response', dataResponse)

        Sentry.captureException(error, {
            extra: {
                payload: dataPayload,
                response: dataResponse,
            },
        })
        const { status } = error?.response

        if (Axios.isCancel(error)) {
            return Promise.reject('FE AXIOS: Promise canceled')
        }

        if (status === 500) {
            Sentry.captureException(error)
            return Promise.reject('FE AXIOS: Server error 500')
        }

        return Promise.reject(error)
    } catch (error) {
        Sentry.captureException(error)
        return Promise.reject('FE AXIOS: Error in error handler')
    }
}

class ApiService {
    http = Axios.create({
        baseURL: API_BASE_URL || undefined,
        timeout: 600000,
    })

    httpService = Axios

    constructor() {
        this.http.interceptors.request.use((config) => {
            return config
        })
    }

    async get(path: string, config?: {}) {
        const options = {
            ...config,
        }
        return this.http.get(path, options).catch(errorHandler)
    }

    async post(path: string, payload: {}, config?: any) {
        const options = {
            ...config,
        }
        return this.http.post(path, payload, options).catch(errorHandler)
    }

    async put(path: string, payload: {}, config?: any) {
        const options = {
            ...config,
        }
        return this.http.put(path, payload, options).catch(errorHandler)
    }

    async postFile(path: string, payload: {}, config?: any) {
        const options = {
            // headers: { 'Content-Type': 'multipart/form-data' },
            ...config,
        }
        return this.http.post(path, payload, options).catch(errorHandler)
    }
}

export const API = new ApiService()
