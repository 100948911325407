import * as React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
    React.useEffect(() => {
        window.location.href = 'https://photogrid.rs/kategorije/'
    }, [])
    return (
        <div>
            <h1>Photogrid</h1>
            <nav>
                <Link to="/squaregrid">SquareGrid</Link>
                <Link to="/tilegrid">TileGrid</Link>
            </nav>
        </div>
    )
}

export default Home
