import * as React from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { Button, Dialog, BottomDialogContent, Box, ErrorBox } from '.'
import InApp from './isInApp'
import InfoIcon from './assets/info_circle.svg'

type ButtonProps = React.ComponentProps<typeof Button>

export interface DropzoneButtonProps extends ButtonProps {
    children: React.ReactNode
    dropzoneOptions: DropzoneOptions
}

export const DropzoneButton = ({
    children,
    dropzoneOptions,
    ...props
}: DropzoneButtonProps) => {
    const { getRootProps, getInputProps, open } = useDropzone({
        ...dropzoneOptions,
        noClick: true,
    })
    const [isOpen, setIsOpen] = React.useState(false)

    const openDialog = () => {
        setIsOpen(true)
    }
    const inApp = new InApp()
    const shouldShowSingleUploadDialog = inApp.isInApp && inApp.isAndroid
    return (
        <>
            <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
                <BottomDialogContent css={{ background: 'transparent' }}>
                    <ErrorBox isAlert icon={InfoIcon}>
                        Dodaj jednu po jednu sliku!
                    </ErrorBox>
                    <Button
                        {...getRootProps()}
                        onClick={() => {
                            setIsOpen(false)
                            open()
                        }}
                        {...props}
                        css={{ width: '100%', padding: '$3 0', marginTop: '$2' }}
                    >
                        <input {...getInputProps()} />
                        Dodaj sliku
                    </Button>
                </BottomDialogContent>
            </Dialog>
            <Button
                {...getRootProps()}
                onClick={shouldShowSingleUploadDialog ? openDialog : open}
                {...props}
            >
                <input {...getInputProps()} />
                {children}
            </Button>
        </>
    )
}
