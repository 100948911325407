import * as React from 'react'
import { useContext, useReducer, Dispatch, ReactNode } from 'react'
import { v4 as uuid } from 'uuid'

type Values<T> = T[keyof T]

export const PRODUCT_IDS = {
    '20x20': 2810,
    '30x30': 2810,
    '20x30': 2813,
    '30x45': 2813,
}

export const SHIPPING_PRICE = 280

export const PRODUCT_PRICES = {
    '20x20': 899,
    '30x30': 1199,
    '20x30': 949,
    '30x45': 1299,
}

export const VARIATION_IDS = {
    '20x20none': 3579,
    '20x20black': 3580,
    '20x20white': 3187,
    '20x20BlackAndWhite': 3581,
    '30x30none': 3588,
    '30x30black': 3587,
    '30x30white': 3589,
    '30x30BlackAndWhite': 3186,
    '20x30none': 3592,
    '20x30black': 3591,
    '20x30white': 3590,
    '20x30BlackAndWhite': 3283,
    '30x45none': 3595,
    '30x45black': 3594,
    '30x45white': 3593,
    '30x45BlackAndWhite': 3282,
}

export const PhotoFrame = {
    Black: 'black',
    None: 'none',
    White: 'white',
    BlackAndWhite: 'BlackAndWhite',
} as const
export type PhotoFrameType = Values<typeof PhotoFrame>

export const PhotoSize = {
    '20x20': '20x20',
    '30x30': '30x30',
    '20x30': '20x30',
    '30x45': '30x45',
} as const
export type PhotoSizeType = Values<typeof PhotoSize>

export const DefaultSizes = {
    squaregrid: PhotoSize['20x20'],
    tilegrid: PhotoSize['20x30'],
} as const

export const BundleDiscount = {
    8: 10,
    12: 15,
    16: 20,
}

export const FREE_SHIPPING_MINIMUM_ORDER = 4000

export const SALE_END_DATE: Date | null = new Date('2024-01-09T01:00:00')
export const SALE_START_DATE: Date | null = new Date('2023-12-17T01:00:00')

export const SALE_AMOUNT: number | null = 20

export const SALE_ACTIVE =
    !!SALE_AMOUNT &&
    !!SALE_END_DATE &&
    !!SALE_START_DATE &&
    new Date().getTime() < SALE_END_DATE?.getTime() &&
    new Date().getTime() > SALE_START_DATE?.getTime()

export interface Scene {
    id: string
    photos: Photo[]
    frame: PhotoFrameType
    size: PhotoSizeType | null
}

export interface Photo {
    id: string
    sourceUrl: string
    croppedUrl: string
    croppedBlob: Blob
    croppedAreaPixels?: any
    initialCroppedAreaPixels?: any
    file: File
    rotation?: number
    dimensions: {
        height: number
        width: number
    }
    debug?: any
}

type AppDispatchType = Dispatch<AppActionReturnType>

interface AppStateType {
    currentScene: Scene & { isEditing?: boolean }
    totalPhotos: number
    currentScenePrice: number
    currentSceneDiscountedPrice: number
    currentSceneDiscountAmount: number
    shippingPrice: number
    globalSale: number | null
    bundleDiscount: number
    activeSale: number | null
    cartScenes: Scene[]
    cartScenesPrice: number
    cartScenesDiscountedPrice: number
    cartScenesDiscountAmount: number
    totalPrice: number
    totalDiscountedPrice: number
    totalDiscountAmount: number
    error: any[]
}

export const defaultAppState: AppStateType = {
    currentScene: {
        id: uuid(),
        photos: [],
        frame: PhotoFrame.None,
        size: null,
    },
    currentScenePrice: 0,
    currentSceneDiscountedPrice: 0,
    currentSceneDiscountAmount: 0,
    bundleDiscount: 0,
    shippingPrice: 0,
    globalSale: SALE_AMOUNT,
    activeSale: SALE_AMOUNT,
    cartScenes: [],
    cartScenesPrice: 0,
    cartScenesDiscountedPrice: 0,
    cartScenesDiscountAmount: 0,
    totalPhotos: 0,
    totalPrice: 0,
    totalDiscountedPrice: 0,
    totalDiscountAmount: 0,
    error: [],
}

export const AppAction = {
    ShowLoading: 'ShowLoading',
    ClearPhotos: 'ClearPhotos',
    AddPhotos: 'AddPhotos',
    RemovePhoto: 'RemovePhoto',
    UpdatePhoto: 'UpdatePhoto',
    UpdateFrame: 'UpdateFrame',
    UpdateSize: 'UpdateSize',
    AddSceneToCart: 'AddSceneToCart',
    RemoveSceneFromCart: 'RemoveSceneFromCart',
    ClearCurrentScene: 'ClearCurrentScene',
    SetCurrentScene: 'SetCurrentScene',
    EditCartScene: 'EditCartScene',
    ClearCart: 'ClearCart',
    SetError: 'SetError',
} as const
export type AppActionType = Values<typeof AppAction>

export const showLoading = (loading = true) =>
    ({ type: AppAction.ShowLoading, loading } as const)
export const addPhotos = (photos: Photo[]) =>
    ({ type: AppAction.AddPhotos, photos } as const)
export const removePhoto = (photoId: Photo['id']) =>
    ({ type: AppAction.RemovePhoto, photoId } as const)
export const updatePhoto = (photoId: Photo['id'], data: Omit<Partial<Photo>, 'id'>) =>
    ({ type: AppAction.UpdatePhoto, photoId, data } as const)
export const updateFrame = (frame: PhotoFrameType) =>
    ({ type: AppAction.UpdateFrame, frame } as const)
export const updateSize = (size: PhotoSizeType) =>
    ({ type: AppAction.UpdateSize, size } as const)
export const clearPhotos = () => ({ type: AppAction.ClearPhotos } as const)
export const addSceneToCart = () => ({ type: AppAction.AddSceneToCart } as const)
export const removeSceneFromCart = (sceneId: string) =>
    ({ type: AppAction.RemoveSceneFromCart, sceneId } as const)
export const clearCurrentScene = () => ({ type: AppAction.ClearCurrentScene } as const)
export const setCurrentScene = (sceneId: string) =>
    ({ type: AppAction.SetCurrentScene, sceneId } as const)
export const editCartScene = (sceneId: string) => ({
    type: AppAction.EditCartScene,
    sceneId,
})
export const setError = (error: any) => ({
    type: AppAction.SetError,
    error,
})
export const clearCart = () => ({
    type: AppAction.ClearCart,
})

export type AppActionReturnType =
    | ReturnType<typeof showLoading>
    | ReturnType<typeof clearPhotos>
    | ReturnType<typeof removePhoto>
    | ReturnType<typeof updatePhoto>
    | ReturnType<typeof updateSize>
    | ReturnType<typeof updateFrame>
    | ReturnType<typeof addPhotos>
    | ReturnType<typeof addSceneToCart>
    | ReturnType<typeof removeSceneFromCart>
    | ReturnType<typeof clearCurrentScene>
    | ReturnType<typeof setCurrentScene>
    | ReturnType<typeof editCartScene>
    | ReturnType<typeof clearCart>
    | ReturnType<typeof setError>

/*----------  Reducer  ----------*/

export const getNextBundleSize = (numberOfPhotos, activeSale) => {
    if (numberOfPhotos < 8 && !activeSale)
        return {
            photosUntilNextBundle: 8 - numberOfPhotos,
            nextBundle: 8,
            nextBundleSale: BundleDiscount?.['8'],
        }

    if (numberOfPhotos < 12)
        return {
            photosUntilNextBundle: 12 - numberOfPhotos,
            nextBundle: 12,
            nextBundleSale: BundleDiscount?.['12'],
        }

    if (numberOfPhotos < 16)
        return {
            photosUntilNextBundle: 16 - numberOfPhotos,
            nextBundle: 16,
            nextBundleSale: BundleDiscount?.['16'],
        }

    return null
}

export const getActiveBundleSize = (numberOfPhotos) => {
    if (numberOfPhotos < 8) return null

    if (numberOfPhotos < 12) return 8

    if (numberOfPhotos < 16) return 12

    if (numberOfPhotos >= 16) return 16

    return null
}

export const getSaleAmount = (numberOfPhotos) => {
    const activeBundleSize = getActiveBundleSize(numberOfPhotos)

    if (!activeBundleSize) return 0

    return BundleDiscount[activeBundleSize]
}

export const appReducer = (
    state: AppStateType,
    action: AppActionReturnType,
): AppStateType => {
    switch (action.type) {
        case AppAction.ClearPhotos:
            return {
                ...state,
                currentScene: {
                    ...state.currentScene,
                    photos: [],
                },
            }
        case AppAction.RemovePhoto:
            return {
                ...state,
                currentScene: {
                    ...state.currentScene,
                    photos: state.currentScene.photos.filter(
                        (p) => p.id !== action.photoId,
                    ),
                },
            }
        case AppAction.UpdatePhoto:
            return {
                ...state,
                currentScene: {
                    ...state.currentScene,
                    photos: state.currentScene.photos.map((p) => {
                        if (p.id !== action.photoId) {
                            return p
                        }

                        return {
                            ...p,
                            ...action.data,
                        }
                    }),
                },
            }
        case AppAction.UpdateFrame:
            return {
                ...state,
                currentScene: {
                    ...state.currentScene,
                    frame: action.frame,
                },
            }
        case AppAction.UpdateSize:
            return {
                ...state,
                currentScene: {
                    ...state.currentScene,
                    size: action.size,
                },
            }
        case AppAction.AddPhotos:
            return {
                ...state,
                currentScene: {
                    ...state.currentScene,
                    photos: [...state.currentScene.photos, ...action.photos],
                },
            }
        case AppAction.AddSceneToCart:
            const tempCurrentScene = state.currentScene
            return {
                ...state,
                cartScenes: [...state.cartScenes, tempCurrentScene],
                currentScene: { ...defaultAppState.currentScene, id: uuid() },
            }
        case AppAction.ClearCart:
            return {
                ...state,
                cartScenes: [],
            }
        case AppAction.RemoveSceneFromCart:
            return {
                ...state,
                cartScenes: state.cartScenes.filter((s) => s.id !== action.sceneId),
            }
        case AppAction.ClearCurrentScene:
            return {
                ...state,
                currentScene: { ...defaultAppState.currentScene, id: uuid() },
            }
        case AppAction.SetCurrentScene:
            const selectedScene = state.cartScenes.find((s) => s.id === action.sceneId)
            return {
                ...state,
                currentScene: selectedScene ?? {
                    ...defaultAppState.currentScene,
                    id: uuid(),
                },
            }
        case AppAction.EditCartScene:
            const sceneToEdit = state.cartScenes.find((s) => s.id === action.sceneId)
            return {
                ...state,
                cartScenes: state.cartScenes.filter((s) => s.id !== action.sceneId),
                currentScene: { ...sceneToEdit!, isEditing: true } ?? {
                    ...defaultAppState.currentScene,
                    id: uuid(),
                },
            }
        case AppAction.SetError:
            return {
                ...state,
                error: [...state.error, action.error],
            }
        default:
            return state
    }
}

export const initializer = (initialValue = defaultAppState) => {
    return initialValue
}

export const AppStateContext = React.createContext<AppStateType | undefined>(undefined)
export const useAppState = () => {
    const context = React.useContext(AppStateContext)
    if (context === undefined) {
        throw new Error('useAppState must be used within AppStateContext')
    }
    return context
}

export const AppDispatchContext = React.createContext<AppDispatchType | undefined>(
    undefined,
)

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext)
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within a AppDispatchContext')
    }
    return context
}

export const AppProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(appReducer, defaultAppState, initializer)

    const totalPhotos =
        state.currentScene.photos.length +
        state.cartScenes.reduce((total, cs) => total + cs.photos.length, 0)

    const activeBundleSize = React.useMemo(
        () => getActiveBundleSize(totalPhotos),
        [totalPhotos],
    )
    const bundleDiscount = activeBundleSize ? BundleDiscount[activeBundleSize] : 0

    const activeSale = SALE_ACTIVE
        ? SALE_AMOUNT > bundleDiscount
            ? SALE_AMOUNT
            : bundleDiscount
        : bundleDiscount

    const currentScenePrice =
        state.currentScene.size && state.currentScene.photos.length
            ? state.currentScene.photos.length * PRODUCT_PRICES[state.currentScene.size]
            : 0

    const currentSceneDiscountAmount = currentScenePrice * (activeSale! / 100)

    const currentSceneDiscountedPrice = Math.floor(
        currentScenePrice - currentSceneDiscountAmount,
    )

    const cartScenesPrice = Math.floor(
        state.cartScenes.reduce(
            (total, cs) => cs.photos.length * PRODUCT_PRICES[cs.size!] + total,
            0,
        ),
    )
    const cartScenesDiscountAmount = Math.floor(cartScenesPrice * (activeSale! / 100))
    const cartScenesDiscountedPrice = Math.floor(
        cartScenesPrice - cartScenesDiscountAmount,
    )

    const totalPrice = currentScenePrice + cartScenesPrice
    const totalDiscountAmount = Math.floor(totalPrice * (activeSale! / 100))
    const totalDiscountedPrice = Math.floor(totalPrice - totalDiscountAmount)

    const shippingPrice =
        cartScenesPrice >= FREE_SHIPPING_MINIMUM_ORDER ? 0 : SHIPPING_PRICE

    return (
        <AppStateContext.Provider
            value={{
                ...state,
                currentScenePrice,
                currentSceneDiscountAmount,
                currentSceneDiscountedPrice,
                activeSale,
                totalPhotos,
                shippingPrice,
                cartScenesPrice,
                cartScenesDiscountAmount,
                cartScenesDiscountedPrice,
                totalPrice,
                totalDiscountAmount,
                totalDiscountedPrice,
                bundleDiscount,
            }}
        >
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}
