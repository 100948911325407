import { Box, styled, Text } from 'design-system'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    editCartScene,
    PhotoFrame,
    PhotoFrameType,
    PhotoSize,
    PhotoSizeType,
    PRODUCT_PRICES,
    removeSceneFromCart,
    Scene,
    useAppDispatch,
    useAppState,
} from '../AppState'

interface Props {
    scene: Scene
}

const getItemTitleBySize = (size: PhotoSizeType | null) => {
    switch (size) {
        case PhotoSize['20x20']:
        case PhotoSize['30x30']:
            return 'SquareGrid'
        case PhotoSize['20x30']:
        case PhotoSize['30x45']:
            return 'TileGrid'
        default:
            return 'SquareGrid'
    }
}

const getItemFrameText = (frame: PhotoFrameType) => {
    switch (frame) {
        case 'BlackAndWhite':
            return 'Crno-beli okvir'
        case 'none':
            return 'Bez okvira'
        case 'white':
            return 'Beli okvir'
        case 'black':
            return 'Crni okvir'
        default:
            return 'Bez okvira'
    }
}

const Img = styled('img')
const CartSceneItem = ({ scene }: Props) => {
    const { activeSale } = useAppState()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const sceneTotalPrice = scene.photos.length * PRODUCT_PRICES[scene.size!]
    const sceneDiscountAmount = sceneTotalPrice * (activeSale! / 100)
    const hasDiscount = sceneDiscountAmount > 0
    const sceneDiscountedPrice = Math.floor(sceneTotalPrice - sceneDiscountAmount)

    const sceneTitle = getItemTitleBySize(scene.size)
    const isSquareGrid = sceneTitle === 'SquareGrid'
    const hasBorder = scene.frame !== PhotoFrame.None
    const isBorderBW = scene.frame === PhotoFrame.BlackAndWhite
    return (
        <Box css={{ display: 'flex', alignItems: 'flex-start', gap: 12 }}>
            <Box
                css={{
                    background: 'linear-gradient(45deg, #F7F3F0 0%, #DDDAD6 100%)',
                    borderRadius: '4px',
                    width: 60,
                    height: 60,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                <Box
                    css={{
                        width: 20,
                        height: isSquareGrid ? 20 : 20 * 1.5,
                        border: isBorderBW ? '1px solid black' : 'none',
                    }}
                >
                    <Img
                        src={scene.photos?.[0].croppedUrl}
                        css={{
                            width: 18,
                            height: isSquareGrid ? 18 : 18 * 1.5,
                            border: isBorderBW
                                ? '1px solid white'
                                : hasBorder
                                ? `1px solid ${scene.frame}`
                                : 'none',
                        }}
                    />
                </Box>
            </Box>
            <Box
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 4,
                }}
            >
                <Text css={{ fontSize: 12, fontWeight: 700 }}>{sceneTitle}</Text>
                <Text css={{ fontSize: 10, fontWeight: 300 }}>
                    {scene.photos.length} x {getItemFrameText(scene.frame)} / {scene.size}
                    cm
                </Text>
                <Box
                    css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        flexGrow: 1,
                        marginTop: 10,
                    }}
                >
                    <Text
                        css={{
                            fontSize: 10,
                            fontWeight: 300,
                            color: '#51A5FF',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            dispatch(editCartScene(scene.id))
                            navigate(`/${sceneTitle.toLowerCase()}`)
                        }}
                    >
                        Izmeni
                    </Text>
                </Box>
            </Box>
            <Box css={{ display: 'flex', flexDirection: 'column' }}>
                <Box css={{ display: 'flex', alignItems: 'center', fontsize: 12 }}>
                    {hasDiscount ? (
                        <Box css={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                            <Text
                                css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    textDecoration: 'line-through',
                                }}
                            >
                                {sceneTotalPrice.toLocaleString('sr-RS')}
                            </Text>
                            <Text
                                css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: '#FF3861',
                                }}
                            >
                                {sceneDiscountedPrice.toLocaleString('sr-RS')} RSD
                            </Text>
                        </Box>
                    ) : (
                        <Text
                            css={{
                                fontSize: 12,
                                fontWeight: 500,
                            }}
                        >
                            {sceneTotalPrice.toLocaleString('sr-RS')} RSD
                        </Text>
                    )}
                </Box>
                <Text
                    css={{
                        fontSize: 10,
                        fontWeight: 300,
                        color: '#000',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        alignSelf: 'flex-end',
                    }}
                    onClick={() => {
                        dispatch(removeSceneFromCart(scene.id))
                    }}
                >
                    Ukloni
                </Text>
            </Box>
        </Box>
    )
}

export default CartSceneItem
