import { styled } from './stitches.config'

export const Button = styled('button', {
    'all': 'unset',
    'display': 'inline-flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'borderRadius': '4px',
    'py': '15px',
    'px': '11px',
    'fontSize': 15,
    'fontWeight': 500,
    'fontFamily': '$regular',
    'whiteSpace': 'nowrap',
    'cursor': 'pointer',
    '&:focus': {
        boxShadow: '$focus',
    },
    '&:disabled': {
        background: 'lightgray',
        color: 'gray',
        cursor: 'not-allowed',
    },
    'variants': {
        variant: {
            primary: {
                background: '$brand',
                color: '#fff',
            },
            secondary: {
                'color': '$brand',
                'background': '$brandBackground',
                '&:disabled': {
                    background: '#f7f7f7',
                    color: 'lightgray',
                    cursor: 'not-allowed',
                },
            },
            ghost: {
                background: 'transparent',
                color: '$brand',
            },
            ghostUnfocusable: {
                'background': 'transparent',
                'color': '$brand',
                'py': '22px',
                '&:focus': {
                    boxShadow: 'none',
                },
            },
            action: {
                background: '#51A5FF',
                color: '#fff',
                py: '12px',
            },
            dropzone: {
                background: '$brandBackground',
                borderColor: '$brand',
                borderWidth: '1px',
                borderStyle: 'dashed',
                color: '$brand',
                fontSize: '18px',
            },
            dark: {
                'background': '#222222',
                'color': '#fff',
                'border': '1px solid #4E4E4E',
                'borderRadius': '6px',
                'fontSize': '14px',
                'fontWeight': 300,
                'lineHeight': '14px',
                'boxShadow':
                    '0px 5px 17px rgba(0, 0, 0, 0.2), 0px 2px 7px rgba(0, 0, 0, 0.15)',
                'px': '16px',
                'py': '12px',
                '&:hover': { backgroundColor: '#333333' },
                '&:focus': { boxShadow: `0 0 0 2px black` },
                '@subPhone': {
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '12px',
                    py: '9px',
                },
            },
        },
    },

    'defaultVariants': {
        variant: 'primary',
    },
})
