import * as React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
    dsn: 'https://17883d82446d45b48ab6758570b3c272@o1276073.ingest.sentry.io/6471367',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
})

const container = document.getElementById('root')

const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(<App />)
