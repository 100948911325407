import { ComponentProps, ReactElement, ReactNode } from 'react'
import { styled } from './stitches.config'

export const IconButtonContainer = styled('button', {
    // Reset
    'alignItems': 'center',
    'appearance': 'none',
    'borderWidth': '0',
    'boxSizing': 'border-box',
    'display': 'inline-flex',
    'flexShrink': 0,
    'fontFamily': 'inherit',
    'fontSize': '14px',
    'justifyContent': 'center',
    'lineHeight': '1',
    'outline': 'none',
    'padding': '0',
    'textDecoration': 'none',
    'userSelect': 'none',
    'WebkitTapHighlightColor': 'transparent',
    'color': '$hiContrast',
    '&::before': {
        boxSizing: 'border-box',
    },
    '&::after': {
        boxSizing: 'border-box',
    },
    'backgroundColor': '$loContrast',
    'border': '1px solid $slate7',
    '@hover': {
        '&:hover': {
            borderColor: '$brand',
        },
    },
    '&:active': {
        backgroundColor: '$slate2',
    },
    '&:focus': {
        borderColor: '$brand',
        boxShadow: '0 0 0 1px $colors$slate8',
    },
    '&:disabled': {
        pointerEvents: 'none',
        backgroundColor: 'transparent',
        color: '$slate6',
    },

    'variants': {
        size: {
            '1': {
                borderRadius: '$1',
                height: '$5',
                width: '$5',
            },
            '2': {
                borderRadius: '$2',
                height: '$6',
                width: '$6',
            },
            '3': {
                borderRadius: '$2',
                height: '$7',
                width: '$7',
            },
            '4': {
                borderRadius: '$3',
                height: '$8',
                width: '$8',
            },
        },
        variant: {
            ghost: {
                'backgroundColor': 'transparent',
                'borderWidth': '0',
                '@hover': {
                    '&:hover': {
                        backgroundColor: '$brandBackground',
                    },
                },
                '&:focus': {
                    boxShadow: 'inset 0 0 0 1px $colors$brand, 0 0 0 1px $colors$brand',
                },
                '&:active': {
                    backgroundColor: '$brandBackground',
                },
                '&[data-radix-popover-trigger][data-state="open"], &[data-radix-dropdown-menu-trigger][data-state="open"]':
                    {
                        backgroundColor: '$slateA4',
                    },
            },
            raised: {
                'boxShadow':
                    '0 0 transparent, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)',
                '@hover': {
                    '&:hover': {
                        boxShadow:
                            '0 0 transparent, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)',
                    },
                },
                '&:focus': {
                    borderColor: '$slate8',
                    boxShadow:
                        '0 0 0 1px $colors$slate8, 0 16px 32px hsl(206deg 12% 5% / 25%), 0 3px 5px hsl(0deg 0% 0% / 10%)',
                },
                '&:active': {
                    backgroundColor: '$slate4',
                },
            },
        },
        state: {
            active: {
                'backgroundColor': '$slate4',
                'boxShadow': 'inset 0 0 0 1px hsl(206,10%,76%)',
                '@hover': {
                    '&:hover': {
                        boxShadow: 'inset 0 0 0 1px hsl(206,10%,76%)',
                    },
                },
                '&:active': {
                    backgroundColor: '$slate4',
                },
            },
            waiting: {
                'backgroundColor': '$slate4',
                'boxShadow': 'inset 0 0 0 1px hsl(206,10%,76%)',
                '@hover': {
                    '&:hover': {
                        boxShadow: 'inset 0 0 0 1px hsl(206,10%,76%)',
                    },
                },
                '&:active': {
                    backgroundColor: '$slate4',
                },
            },
        },
    },
    'defaultVariants': {
        size: '1',
        variant: 'ghost',
    },
})

interface Props extends ComponentProps<typeof IconButtonContainer> {
    icon: string | any
    alt?: string
    isActive?: boolean
    fill?: string
}

export const IconButton = ({ icon, alt, fill, isActive, ...rest }: Props) => {
    const Icon = icon
    return (
        <IconButtonContainer {...rest}>
            {typeof icon === 'string' ? (
                <img src={icon} alt={alt || ''} />
            ) : (
                <Icon fill={isActive ? '#FF3861' : fill || 'black'} />
            )}
        </IconButtonContainer>
    )
}
