import { Dialog, DialogContent, PhotoEditor, PhotoEditorProps } from 'design-system'
import * as React from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { updatePhoto, useAppDispatch, useAppState } from '../AppState'
import { usePhotoRatio } from '../hooks'

const EditPhotoDialog = () => {
    const navigate = useNavigate()
    const { currentScene } = useAppState()
    const { photoIndex, product } = useParams()
    const ratio = usePhotoRatio()
    const dispatch = useAppDispatch()
    const photo = React.useMemo(
        () => currentScene.photos?.[Number(photoIndex!)] || null,
        [currentScene.photos, photoIndex],
    )
    const [isOpen, setIsOpen] = React.useState(true)
    const onEditComplete: PhotoEditorProps['onEditComplete'] = ({
        croppedImage,
        croppedAreaPixels,
        rotation,
        debug,
    }) => {
        try {
            dispatch(
                updatePhoto(photo?.id, {
                    croppedUrl: URL.createObjectURL(croppedImage),
                    croppedBlob: croppedImage,
                    croppedAreaPixels,
                    rotation,
                    debug,
                }),
            )
        } catch (e) {
            console.error(e)
        }
    }

    React.useEffect(() => {
        if (!isOpen) {
            setTimeout(() => {
                navigate(-1)
            }, 1)
        }
    }, [isOpen])

    if (!photoIndex || !photo) return <Navigate to={`/${product}`} replace />

    return (
        <Dialog
            defaultOpen={true}
            open={isOpen}
            onOpenChange={(open) => {
                if (!open) {
                    setIsOpen(false)
                }
            }}
        >
            <DialogContent
                css={{
                    'p': 0,
                    '@abovePhone': {
                        width: '450px',
                        maxHeight: '90vh',
                    },
                }}
            >
                <PhotoEditor
                    imageUri={photo.sourceUrl}
                    aspect={ratio}
                    onEditComplete={onEditComplete}
                    photo={photo}
                    onEditCancel={() => {
                        setIsOpen(false)
                    }}
                />
            </DialogContent>
        </Dialog>
    )
}
export default EditPhotoDialog
