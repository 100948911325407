import { Box, Button, IconButton, RadioGroup, RadioItem } from 'design-system'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
    defaultAppState,
    DefaultSizes,
    PhotoFrame,
    PhotoFrameType,
    PhotoSize,
    PhotoSizeType,
    updateFrame,
    updateSize,
    useAppDispatch,
    useAppState,
} from '../AppState'

import FrameNone from '../assets/frame_none.png'
import FrameBlack from '../assets/frame_black.png'
import FrameWhite from '../assets/frame_white.png'
import FrameMixed from '../assets/frame_mixed.png'
import ChevronDown from '../assets/icons/chevron_down.svg'
import ChangeSizeIcon from '../assets/icons/change_size.svg'
import ChangeFrameIcon from '../assets/icons/change_frame.svg'

interface PickerItemProps {
    value: string
    isSelected: boolean
    imageUrl?: string
    children?: ReactNode
}

export const PickerItem = ({
    imageUrl,
    value,
    isSelected,
    children,
}: PickerItemProps) => {
    const radioRef = useRef<HTMLButtonElement>(null)

    return (
        <RadioItem
            value={value}
            ref={radioRef}
            css={{
                'width': '46px',
                'height': '46px',
                'border': `2px solid ${isSelected ? '#85DD58' : '#fff'}`,
                'borderRadius': '4px',
                'backgroundImage': imageUrl ? `url("${imageUrl}")` : 'none',
                'backgroundColor': isSelected ? '#fff' : '#A9B0B9',
                'backgroundPosition': 'center',
                'position': 'relative',
                'display': 'flex',
                'alignItems': 'center',
                'justifyContent': 'center',
                'fontSize': '10px',
                'fontWeight': 'bold',
                'color': isSelected ? '#85DD58' : '#fff',
                '&:before': {
                    content: '',
                    position: 'absolute',
                    inset: 0,
                    background: '#85DD58',
                    opacity: isSelected ? 0.4 : 0,
                },
            }}
        >
            {children && children}
        </RadioItem>
    )
}

export const FramePicker = ({ onClose }) => {
    const dispatch = useAppDispatch()
    const { currentScene } = useAppState()

    return (
        <Box
            css={{
                display: 'flex',
                position: 'relative',
                width: '100%',
                px: '6px',
            }}
        >
            <RadioGroup
                defaultValue={currentScene.frame || defaultAppState.frame}
                aria-label="Okvir"
                onValueChange={(value: PhotoFrameType) => dispatch(updateFrame(value))}
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    gap: 8,
                }}
            >
                <PickerItem
                    value={PhotoFrame.None}
                    imageUrl={FrameNone}
                    isSelected={currentScene.frame === PhotoFrame.None}
                />
                <PickerItem
                    value={PhotoFrame.White}
                    imageUrl={FrameWhite}
                    isSelected={currentScene.frame === PhotoFrame.White}
                />
                <PickerItem
                    value={PhotoFrame.Black}
                    imageUrl={FrameBlack}
                    isSelected={currentScene.frame === PhotoFrame.Black}
                />
                <PickerItem
                    value={PhotoFrame.BlackAndWhite}
                    imageUrl={FrameMixed}
                    isSelected={currentScene.frame === PhotoFrame.BlackAndWhite}
                />
            </RadioGroup>

            <Box
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    right: 0,
                    width: '46px',
                    height: '46px',
                }}
            >
                <IconButton onClick={onClose} icon={ChevronDown} />
            </Box>
        </Box>
    )
}

export const SizePicker = ({ onClose }) => {
    const dispatch = useAppDispatch()
    const { currentScene } = useAppState()
    const { product } = useParams()

    return (
        <Box css={{ display: 'flex', position: 'relative', width: '100%', px: '6px' }}>
            <RadioGroup
                defaultValue={currentScene.size || DefaultSizes[product!]}
                aria-label="Velicina"
                onValueChange={(value: PhotoSizeType) => dispatch(updateSize(value))}
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    gap: 8,
                }}
            >
                {product === 'squaregrid' ? (
                    <React.Fragment>
                        <PickerItem
                            value={PhotoSize['20x20']}
                            isSelected={currentScene.size === PhotoSize['20x20']}
                        >
                            20x20
                        </PickerItem>
                        <PickerItem
                            value={PhotoSize['30x30']}
                            isSelected={currentScene.size === PhotoSize['30x30']}
                        >
                            30x30
                        </PickerItem>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <PickerItem
                            value={PhotoSize['20x30']}
                            isSelected={currentScene.size === PhotoSize['20x30']}
                        >
                            20x30
                        </PickerItem>
                        <PickerItem
                            value={PhotoSize['30x45']}
                            isSelected={currentScene.size === PhotoSize['30x45']}
                        >
                            30x45
                        </PickerItem>
                    </React.Fragment>
                )}
            </RadioGroup>
            <Box
                css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    right: 0,
                    width: '46px',
                    height: '46px',
                }}
            >
                <IconButton onClick={onClose} icon={ChevronDown} />
            </Box>
        </Box>
    )
}

const OptionsPicker = () => {
    const location = useLocation()

    const [activePicker, setActivePicker] = useState<string | null>(null)

    useEffect(() => {
        setActivePicker(null)
    }, [location])

    return (
        <Box
            css={{
                'display': 'flex',
                'jc': 'space-between',
                'width': '100%',
                'px': '8px',
                'pb': '12px',
                'minHeight': 58,
                'alignItems': 'flex-end',
                '@abovePhone': {
                    display: 'none',
                },
                'background': activePicker
                    ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 56.66%, rgba(0, 0, 0, 0) 100%)'
                    : 'unset',
            }}
        >
            {!activePicker ? (
                <>
                    <Button onClick={() => setActivePicker('size')} variant={'dark'}>
                        <Box css={{ width: '14px', height: '14px', marginRight: '8px' }}>
                            <img src={ChangeSizeIcon} alt="" />
                        </Box>
                        Veličina
                    </Button>
                    <Button onClick={() => setActivePicker('frame')} variant={'dark'}>
                        Okvir
                        <Box css={{ width: '14px', height: '14px', marginLeft: '8px' }}>
                            <img src={ChangeFrameIcon} alt="" />
                        </Box>
                    </Button>
                </>
            ) : activePicker === 'frame' ? (
                <FramePicker onClose={() => setActivePicker(null)} />
            ) : (
                <SizePicker onClose={() => setActivePicker(null)} />
            )}
        </Box>
    )
}

export default OptionsPicker
